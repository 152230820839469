import React from "react";
import axios from "axios";
// import { Link } from "react-router-dom";
import "../assets/styles/register_user_flow.css";
import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
export default function CreateComponent() {
  const [error, setError] = useState();
  const params = useParams();
  // // // // //(data.data.formValues);
  // // // // //("kushagra");

  const [teamname, setTeam] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [aa_email, setAAEmail] = useState(null);
  const [online, setOnline] = useState(false);
  const handleInputChange = (event) => {
    if (
      event?.target?.value.length > 15 ||
      event?.target?.value.trim() === "" ||
      event?.target?.value === null ||
      event?.target?.value.length < 1
    ) {
      setErrorMsg(
        "Choose team name under 15 characters in length and it cannot be empty",
      );
      setTimeout(() => {
        setErrorMsg("");
      }, 3000);
      return;
    }
    setTeam(event?.target?.value);
    setErrorMsg("");
  };
  useEffect(() => {
    const email = localStorage.getItem("email");
    setAAEmail(email);
  }, []);

  const validateName = (e) => {
    if (teamname === "") {
      setErrorMsg("This field is empty");
      setTimeout(() => {
        setErrorMsg("");
      }, 20000);
      return;
    } else if (teamname.length > 15) {
      setErrorMsg("Choose team name under 15 characters in length");
      setTimeout(() => {
        setErrorMsg("");
      }, 20000);
      return;
    } else {
      // create_team/:id
      // // // //(params?.id);
      // // // // //(team);
      const jwtToken = localStorage.getItem("user");
      axios
        .post(
          `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/api/create_team/` +
            params?.id,
          { teamname: teamname.trim(), aa_email: aa_email, online },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json", // Assuming you're sending JSON data in the request body
            },
          },
        )
        .then((res) => {
          // Handle the message in the React component
          // // // //(res?.data?.redirect);
          setTeam("");
          // window.location.href = `https://main--calm-daifuku-2a2bdc.netlify.app/api${res?.data?.redirect}`;
          window.location.replace(`/api${res?.data?.redirect}`);
          // navigate("/api/team/:id");
          // navigate(`${response?.data?.redirectUrl}`);
          // navigate( navigate(`${response?.data?.redirectUrl}`))
        })
        .catch((error) => {
          // // // //(error);
          setError(error?.response?.data?.error);
          // Handle error state
        });

      // setTName("");
    }
  };
  function navigateToPage() {
    const desiredPageUrl = "/login"; // Replace with the URL of the page you want to navigate to
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("value");
    window.location.href = desiredPageUrl;
  }

  // Add an event listener for the popstate event
  window.addEventListener("popstate", (event) => {
    // Execute the function to navigate the user to the desired page
    navigateToPage();
  });

  // Push a new state to the history when the user lands on the current page
  // This will ensure that there's always a state in the history stack for this page
  window.history.pushState({ page: "current" }, null, window.location.href);
  window.history.pushState({ page: "current" }, null, window.location.href);
  // // // //();
  // // // // //(createTeam);
  useEffect(() => {
    document.title = "Create Team";
    const favicon = document.getElementById("favicon");
    favicon.href =
      "https://htm-4-images.s3.amazonaws.com/img/HTMLOGHO_%20NEW.png";
    return () => {
      document.title = "Portal | HTM";
    };
  }, []);
  //
  return (
    <>
      <div className="user-app-box">
        <div className="user-container">
          <h1 style={{ marginBottom: "40px" }}>CREATE TEAM</h1>
          <form autoComplete="off">
            <input
              type="text"
              id="teamName"
              name="teamName"
              placeholder="Enter the team name"
              required
              value={teamname}
              onChange={handleInputChange}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault(); // Prevent the default form submission behavior
                  validateName();
                }
              }}
            />
            <div
              style={{
                display: "flex",
                direction: "row",
                marginTop: "16px",
                gap: "8px",
                fontFamily: "Poppins, sans-serif",
                letterSpacing: "0.8px",
                width: "55%",
                fontSize: "14px",
              }}
            >
              <input
                type="checkbox"
                onChange={() => setOnline(!online)}
                id="online"
              />
              <label htmlFor="online">Online Participation</label>
            </div>
            {error && <h4 style={{ color: "red" }}>{error}</h4>}
            <button
              type="button"
              onClick={validateName}
              disabled={errorMsg !== ""}
            >
              CREATE
            </button>
          </form>
          <p
            style={{
              marginTop: "-5px",
              color: "red",
              fontFamily: "Poppins, sans-serif",
              fontWeight: "600",
            }}
          >
            {errorMsg}
          </p>
          <div className="prompt_c">
            Want to join team?{" "}
            <Link to={`/api/join/${params?.id}`}>Join Team</Link>
          </div>
        </div>
      </div>
    </>
  );
}
