import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const ArrowBackButton = ({ url }) => {
  const goBack = () => {
    window.location.replace(url); // This will navigate back using the browser's history
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      // Prevent default form submission
    }
  };

  return (
    <button
      onKeyDown={handleKeyPress}
      onClick={goBack}
      className="arrow-back-button"
    >
      <i class="fa-solid fa-circle-arrow-left"></i>
    </button>
  );
};

export default ArrowBackButton;
