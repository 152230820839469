import React, { useState, useEffect } from "react";
import "./TagsInput.css"; // Import your CSS file for styling

const TagsInput = ({ tags, selectedTags }) => {
  const [tagList, setTagList] = useState([]);
  // //(tags, selectedTags);
  useEffect(() => {
    setTagList(tags || []);
  }, [tags]);

  const removeTag = (index) => {
    const updatedTags = [...tagList];
    updatedTags.splice(index, 1);
    setTagList(updatedTags);
    selectedTags(updatedTags);
  };

  const addTag = (event) => {
    if (event.target.value !== "") {
      const newTag = event.target.value;
      const updatedTags = [newTag, ...tagList]; // Place new tag in front
      setTagList(updatedTags);
      selectedTags(updatedTags);
      event.target.value = "";
    }
  };
  // background-color: rgba(165, 164, 177, 0.24);

  return (
    <div className="tags-input">
      <div id="tags" className="tag-inputs">
        {tagList?.map((tag, index) => (
          <span key={index} className="tag">
            {tag}
            <span className="tag-close-icon" onClick={() => removeTag(index)}>
              x
            </span>
          </span>
        ))}
        <input
          type="text"
          onKeyDown={(event) => (event.key === "Enter" ? addTag(event) : null)}
          placeholder="Press Enter to add more "
        />
      </div>
    </div>
  );
};

export default TagsInput;
