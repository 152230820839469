import React from "react";
import "../assets/styles/bottombar.css";
import { Link } from "react-router-dom";
export default function BottomBar() {
  return (
    <div className="bottomBar">
      <div className="bottom-bar">
        <Link to="/admin/home" className="link-active">
          <i className="fa-solid fa-house"></i>
          Home
        </Link>
        <Link to="/admin/member" className="link-active">
          <i className="fa-solid fa-people-group"></i>
          Idea Submission
        </Link>
        <Link to="/admin/team" className="link-active">
          <i className="fa-sharp fa-solid fa-address-book"></i>
          Team List
        </Link>
        <Link to="/admin/stats" className="link-active">
          <i className="fa-solid fa-gear"></i>
          Statistics
        </Link>
        <Link to="/admin/collection" className="link-active">
          <i className="fa-solid fa-people-group"></i>
          Member Details
        </Link>
      </div>
    </div>
  );
}
