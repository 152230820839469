import React, { useState, useEffect } from "react";
import axios from "axios";
import "../assets/styles/team-details.css";
const entriesPerPage = 8;
export default function TeamDetails() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const handlePreviousPage = () => {
    setExpandedIndex(-1);
    setCurrentPage(currentPage - 1);
  };
  const handleNextPage = () => {
    setExpandedIndex(-1);
    setCurrentPage(currentPage + 1);
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when search query changes
  };
  const startIndex = (currentPage - 1) * entriesPerPage;
  const endIndex = startIndex + entriesPerPage;
  const toggleAccordion = (index) => {
    if (index === expandedIndex) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  const [teamsData, setTeamsData] = useState([]);
  let currentData;
  const [filteredTeams, setFilteredTeams] = useState([]);

  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/teams`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        // //("yash varshney");
        //(res);
        const filtered = res?.data.filter((team) =>
          team.teamname.toLowerCase().startsWith(searchQuery.toLowerCase()),
        );
        setTeamsData(res?.data);
        setFilteredTeams(filtered);
        // //(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, [searchQuery]);

  currentData = searchQuery
    ? filteredTeams?.slice(startIndex, endIndex)
    : teamsData?.slice(startIndex, endIndex);

  const [getOneData, setGetOneData] = useState(null);
  const getTeamData = (id) => {
    setGetOneData([]);
    const jwtToken = localStorage.getItem("token");
    //("getTeamData called with ID:", id);
    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/team/users/${id}`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        //(res?.data);

        setGetOneData(res?.data);
        // //(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  };
  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(filteredTeams?.length / entriesPerPage);
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setExpandedIndex(-1);
    }
  };
  return (
    <div className="team-details">
      <h1>TEAM DETAILS</h1>
      <div className="search-bar">
        <i className="fa fa-search" aria-hidden="true"></i>
        <input
          placeholder="Search for team details"
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </div>
      <div className="team-table">
        <table className="table-container" style={{ width: "60%" }}>
          <tbody style={{ width: "100%" }}>
            {currentData?.map((team, index) => (
              <React.Fragment key={index}>
                <tr
                  className="details"
                  onClick={() => toggleAccordion(index)}
                  style={{
                    width: "77.5%",
                    marginLeft: "20px",
                    marginRight: "40px",
                  }}
                >
                  <td>{team.teamname}</td>
                  <td>{team.online ? "(Online Team)" : "(Offline Team)"} </td>
                  <td
                    className="see-details"
                    onClick={() => {
                      getTeamData(team?.teamId);
                    }}
                    style={{
                      width: "40%",
                      marginRight: "20px",
                    }}
                  >
                    See Details <i className="fa-solid fa-circle-right"></i>
                  </td>
                </tr>

                <div
                  className="table-scroll-container"
                  style={{
                    width: "80%",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  <table className="table-container">
                    {expandedIndex === index && (
                      <tr>
                        <td className="name" colSpan="17">
                          <table>
                            <thead>
                              <tr>
                                <th>Member</th>
                                <th>Contact No.</th>
                                <th>Gender</th>
                                <th>Age</th>
                                <th>Course & Year</th>
                                <th>Organization</th>
                                <th>Address</th>
                                <th>Country</th>
                                <th>State</th>
                                <th>Pincode</th>
                                <th>Shirt Size</th>
                                <th>Bio</th>
                                <th>Linkedin</th>
                                <th>Twitter</th>
                                <th>Github</th>
                              </tr>
                            </thead>
                            <tbody>
                              {getOneData?.map(
                                (data) => (
                                  <tr style={{ border: "1px solid" }}>
                                    <td>{data.ac_name}</td>
                                    <td style={{ border: "1px solid" }}>
                                      {data.c_phone}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {data.e_gender}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {data.d_age}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {data.h_course_year}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {data.i_organization}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {data.j_address}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {data.l_country}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {data.k_res_state}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {data.m_pincode}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {data.f_tShirtSize}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {data.n_bio}
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      <a
                                        href={data.p_linkedin}
                                        target="_blank"
                                        style={{ textDecoration: "none" }}
                                      >
                                        Linkedin
                                      </a>
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {" "}
                                      <a
                                        href={data.q_twitter}
                                        target="_blank"
                                        style={{ textDecoration: "none" }}
                                      >
                                        Twitter
                                      </a>
                                    </td>
                                    <td style={{ border: "1px solid" }}>
                                      {" "}
                                      <a
                                        href={data.o_github}
                                        target="_blank"
                                        style={{ textDecoration: "none" }}
                                      >
                                        Github
                                      </a>
                                    </td>
                                  </tr>
                                ),
                                // //("done")
                              )}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </table>
                </div>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="previous-btn"
          disabled={currentPage === 1}
          onClick={handlePreviousPage}
        >
          Previous
        </button>
        <span
          className="editable-span"
          contentEditable={true}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              const newPage = parseInt(e.currentTarget.textContent);
              handlePageChange(newPage);
              e.currentTarget.blur(); // Remove focus after Enter is pressed
            }
          }}
          onBlur={(e) => {
            const newPage = parseInt(e.currentTarget.textContent);
            handlePageChange(newPage);
          }}
        >
          {currentPage}
        </span>
        <span>/ {Math.ceil(filteredTeams?.length / entriesPerPage)}</span>
        <button
          className="next-btn"
          disabled={endIndex >= filteredTeams?.length}
          onClick={handleNextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
}
