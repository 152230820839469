import React, { useState, useEffect } from "react";

const Countdown = () => {
  const calculateTimeLeft = () => {
    const countDownDate = new Date("Sep 03, 2024 00:00:00").getTime();
    const now = new Date().getTime();
    const distance = countDownDate - now;

    const timeLeft = {
      days: Math.floor(distance / (1000 * 60 * 60 * 24)),
      hours: Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((distance % (1000 * 60)) / 1000),
    };

    return distance > 0 ? timeLeft : null;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if (!timeLeft) {
    return <div className="countdown-timer">EXPIRED</div>;
  }

  return (
    <div
      className="countdown-timer"
      style={{
        zIndex: "5000",
        width: "100px",
        height: "full",
        color: "black",
        textAlign: "center",
        margin: "10px",
        padding: "10px",
        fontSize: "22px",
      }}
    >
      {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
    </div>
  );
};

export default Countdown;
