import React, { useEffect, useState } from "react";
import bg from "../assets/images/background_1.png";
import NavbarUser from "../components/NavbarUser";
import JoinComponent from "../components/Join";

const Join = (data) => {
  return (
    <div
      className="wrapper"
      style={{
        background: "linear-gradient(135deg, #588380, #2f4443)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <NavbarUser />
      <JoinComponent data={data} />
    </div>
  );
};

export default Join;
