import React, { useEffect, useState, useRef } from "react";
import "../assets/styles/statistics.css";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import Maps from "./Maps";
import MemberDetails from "./MemberDetails";
import Switch from "react-switch";
import { FaPlus, FaTrash } from "react-icons/fa";
import json2csv from "json2csv";
import { saveAs } from "file-saver";

const DownloadCSVButton = ({ data }) => {
  const convertToCSV = (json) => {
    const fields = Object.keys(json[0]);
    const csvData = json2csv.parse(json, { fields });
    return csvData;
  };

  const handleDownload = () => {
    const csvData = convertToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "data.csv");
  };

  return (
    <button
      style={{
        backgroundColor: "#623ec9",
        color: "white",
        padding: "1rem",
        borderRadius: "1rem",
        border: "none",
        cursor: "pointer",
        fontSize: "1.5rem",
        fontWeight: "bold",
        marginRight: "2rem",
        marginLeft: "2rem",
      }}
      onClick={handleDownload}
    >
      Download
    </button>
  );
};

const TotalReg = () => {
  const [data, setData] = useState();
  const [registrationPhase, setRegistrationPhase] = useState(false);
  const [ideaSubmissionPhase, setIdeaSubmissionPhase] = useState(false);

  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(`https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/stats`)
      .then((res) => {
        // // conso("yash varshney");
        // conso(res);
        setRegistrationPhase(res?.data[0]?.registration);
        setIdeaSubmissionPhase(res?.data[0]?.ideaSubmission);
        // // conso(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);
  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/total-registrations`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        // // conso("yash varshney");
        // conso(res);
        setData(res?.data);
        // // conso(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);
  const handleRegistrationToggle = () => {
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/toggle/registration`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        // // conso("yash varshney");
        // conso(res);
        // conso("errorrororo");
        // // conso(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
    setRegistrationPhase(!registrationPhase);
  };

  const handleIdeaSubmissionToggle = () => {
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/toggle/ideaSubmission`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        // // conso("yash varshney");
        // conso(res);
        // // conso(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
    setIdeaSubmissionPhase(!ideaSubmissionPhase);
  };
  const [track, setTracks] = useState();
  const [trackArray, setTrackArray] = useState([]);
  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(`https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/stats`)
      .then((res) => {
        // conso(res);
        const tracksString = res?.data[0]?.tracks;
        // conso(tracksString);

        if (tracksString) {
          const tracksArray = tracksString
            .split(",")
            .map((track) => track.trim());
          // conso(tracksArray);
          setTrackArray(tracksArray);
        }
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);

  const handleUpdateTracks = (action, index, newTrack) => {
    if (action === "add") {
      const updatedTracks = [...trackArray, newTrack];
      setTrackArray(updatedTracks);
      setButton(true);
    } else if (action === "delete") {
      const updatedTracks = trackArray.filter((_, i) => i !== index);
      setTrackArray(updatedTracks);
      setButton(true);
    }
    // conso(action, index, newTrack);

    // // conso(trackArray);
  };

  const isMounted = useRef(true); // Ref to track component mount state
  const [button, setButton] = useState(false);
  useEffect(() => {
    // Set the component as mounted
    isMounted.current = true;

    // Create a cancel token source
    const cancelTokenSource = axios.CancelToken.source();

    const newTracks = trackArray.join(", ");
    // //(newTracks);

    const jwtToken = localStorage.getItem("token");
    if (button) {
      axios
        .post(
          `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/update/track`,
          { newTracks },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json",
            },
            cancelToken: cancelTokenSource.token, // Attach the cancel token to the request
          },
        )

        .then((res) => {
          if (isMounted.current) {
            //(res);
          }
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            if (isMounted.current) {
              //("Request canceled:", error.message);
            }
          } else {
            if (isMounted.current) {
              console.error("Error making the request:", error);
            }
          }
        });
    }

    // Cleanup function to cancel the request and update the mounted state
    return () => {
      isMounted.current = false; // Set the component as unmounted
      cancelTokenSource.cancel("Component unmounted, request canceled");
    };
  }, [trackArray]);

  // Rest of your component code

  const [dataa, setDataa] = useState();
  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/users`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        // // conso("yash varshney");
        // conso(res?.data);
        setDataa(res?.data);
        // // conso(teamsData);
      })
      .catch((error) => {
        console.error("Error making the inrequest:", error);
      });
  }, []);

  return (
    <div className="stats-page home-page">
      <h1
        style={{
          color: "#623ec9",
          display: "flex",
          justifyContent: "center",

          marginTop: "0px",
          marginBottom: "0px",
        }}
      >
        Registration Details
      </h1>
      <div className="phase-toggle">
        <div className="phase-toggle-registrations">
          <span className="registrationH1">Registration Phase:</span>
          <div className="registrationH2">
            <Switch
              onChange={handleRegistrationToggle}
              checked={registrationPhase}
              // Custom styles for the toggle switch
              // Color when switched on
              // Color when switched off
              // Color of the handle when switched off
              width={75} // Width of the toggle switch
              height={40} // Height of the toggle switch
            />
          </div>
        </div>
        <div className="phase-toggle-registrations">
          <span className="registrationH1">Idea Submission Phase:</span>
          <div className="registrationH2">
            <Switch
              onChange={handleIdeaSubmissionToggle}
              checked={ideaSubmissionPhase}
              // Custom styles for the toggle switch
              // Color when switched on
              // Color when switched off
              // Color of the handle when switched off
              width={75} // Width of the toggle switch
              height={40} // Height of the toggle switch
            />
          </div>
        </div>

        <div className="registrationDetails">
          <div
            style={{
              display: "flex",

              flexDirection: "column",
              border: "1px",
              padding: "2rem",
              boxShadow: "rgba(0, 0, 0, 0.21) 0px 0px 81px 0px",
              borderRadius: "1rem",
            }}
          >
            <h2
              style={{
                color: "#623ec9",
                display: "flex",
                justifyContent: "center",
                fontSize: "25px",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              Total User Registrations
            </h2>
            <h2
              style={{
                textAlign: "center",
                color: "#623ec9",
                margin: "0 auto",
                fontFamily: "Poppins, sans-serif",
                fontSize: "55px",
                paddingTop: "30px",
              }}
            >
              {data?.Registrations}
            </h2>
          </div>
          <div
            style={{
              display: "flex",

              flexDirection: "column",
              border: "1px",
              padding: "2rem",
              boxShadow: "rgba(0, 0, 0, 0.21) 0px 0px 81px 0px",
              borderRadius: "1rem",
            }}
          >
            <h2
              style={{
                color: "#623ec9",
                display: "flex",
                justifyContent: "center",
                fontSize: "25px",
                marginTop: "0px",
                marginBottom: "0px",
              }}
            >
              Total Team Registrations
            </h2>
            <h2
              style={{
                textAlign: "center",
                color: "#623ec9",
                margin: "0 auto",
                fontFamily: "Poppins, sans-serif",
                fontSize: "55px",
                paddingTop: "30px",
              }}
            >
              {data?.Teams}
            </h2>
          </div>
        </div>
        {/* <div className="tracks-full">
          <div style={{}} className="tracks-container">
            <h1
              style={{
                marginBottom: "1rem",
                fontSize: "2rem",
                color: "#623ecd",
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              Total Tracks
            </h1>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
                width: "100%",

                // Remove the border
              }}
            >
              <div
                style={{
                  marginTop: "1rem",
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  width: "100%",
                }}
              >
                <input
                  type="text"
                  placeholder="Enter track name"
                  style={{
                    padding: "0.5rem",
                    marginRight: "0.5rem",
                    border: "1px solid #ccc",
                    borderRadius: "0.5rem",
                    width: "100%",
                    height: "2rem",
                  }}
                  value={track}
                  onChange={(e) => setTracks(e.target.value)}
                />
                <FaPlus
                  style={{
                    cursor: "pointer",
                    color: "green",
                    fontSize: "1.5rem",
                  }}
                  onClick={() => {
                    if (track.trim() !== "") {
                      handleUpdateTracks("add", null, track);
                      setTracks(""); // Clear the input field after adding
                    }
                  }}
                />
              </div>
              {trackArray.map((data, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: "0.5rem",
                    border: "1px solid #ccc",
                    borderRadius: "0.5rem",
                  }}
                >
                  <p style={{ margin: 0 }}>{data}</p>
                  <FaTrash
                    style={{ cursor: "pointer", color: "red" }}
                    // Pass the correct index when calling the function
                    onClick={() => handleUpdateTracks("delete", index, track)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div> */}
        <div className="download-button">
          <h1
            style={{
              color: "#623ec9",
              display: "flex",
              justifyContent: "center",
              fontSize: "25px",
              marginLeft: "2rem",
            }}
          >
            Download Member Details
          </h1>
          <DownloadCSVButton data={dataa} />
        </div>
      </div>
    </div>
  );
};

export default TotalReg;
