import React from "react";
import "../assets/styles/navbar.css";

export default function Navbar() {
  //
  return (
    <>
      <div className="navbar">
        <img
          src={require("../assets/images/htm_logo.png")}
          alt="html_log"
          className="nav-img"
        />
      </div>
    </>
  );
}
