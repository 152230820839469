import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../assets/styles/register_user_flow.css";
import { useParams } from "react-router-dom";
export default function WelcomeComponent() {
  const params = useParams();
  // //(params);
  // // //(data);
  // // //("yash");
  function navigateToPage() {
    const desiredPageUrl = "/login"; // Replace with the URL of the page you want to navigate to
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("value");
    window.location.href = desiredPageUrl;
  }

  // Add an event listener for the popstate event
  window.addEventListener("popstate", (event) => {
    // Execute the function to navigate the user to the desired page
    navigateToPage();
  });

  // Push a new state to the history when the user lands on the current page
  // This will ensure that there's always a state in the history stack for this page
  window.history.pushState({ page: "current" }, null, window.location.href);
  window.history.pushState({ page: "current" }, null, window.location.href);
  useEffect(() => {
    document.title = "Welcome To HTM";
    const favicon = document.getElementById("favicon");
    favicon.href =
      "https://htm-4-images.s3.amazonaws.com/img/HTMLOGHO_%20NEW.png";
    return () => {
      document.title = "Portal | HTM";
    };
  }, []);
  //
  return (
    <>
      <div className="user-app-box">
        <div className="user-container">
          <h1>WELCOME TO HTM PORTAL</h1>
          <div className="user-welcome">
            <Link to={`/api/create/${params.id}`}>
              <button className="welc-btn">CREATE TEAM</button>
            </Link>
            <p className="welc-desc">━━━━━ or ━━━━━</p>
            <Link to={`/api/join/${params.id}`}>
              <button className="welc-btn">JOIN TEAM</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
