import React from "react";
import Navbar from "../components/Navbar";
import bg from "../assets/images/background_1.png";
import ForgotComponent from "../components/Forgot";
import { useEffect } from "react";
export default function Forgot() {
  return (
    <div
      className="wrapper"
      style={{
        background: "linear-gradient(135deg, #588380, #2f4443)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Navbar />
      <ForgotComponent />
    </div>
  );
}
