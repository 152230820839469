import React from "react";
import Sidebar from "../components/Sidebar";
import NavbarUser from "../components/AdminNavbar";
import TeamDetails from "../components/TeamDetails";
import BottomBar from "../components/BottomBar";
import bg from "../assets/images/solid-color-image.png";
import { useEffect, useState } from "react";
import TeamMemberDetails from "../components/TeamMemberDetails";
export default function Admin() {
  const [scrollHeight, setScrollHeight] = useState(100);

  const handleScroll = () => {
    const pixelsScrolled = Math.min(window.scrollY, 100); // Limit to 50 pixels
    setScrollHeight(100 + pixelsScrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className="apper"
      style={{
        background: "linear-gradient(135deg, #588380, #2f4443)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <NavbarUser />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Sidebar />
        <TeamMemberDetails />
      </div>
      <BottomBar />
    </div>
  );
}
