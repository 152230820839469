import React, { useState, useEffect } from "react";
import "../assets/styles/team-details.css";
import axios from "axios";

const entriesPerPage = 10;

export default function TeamDetails() {
  const [ideasub, setIdeaSub] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const handleShowDetails = (item) => {
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setSelectedItem(null);
  };

  const handlePreviousPage = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1);
  };

  const startIndex = (currentPage - 1) * entriesPerPage;
  const endIndex = startIndex + entriesPerPage;

  const handleApprove = (itemIndex) => {
    const updatedData = ideasub?.map((item, index) => {
      if (item.teamId === itemIndex) {
        const jwtToken = localStorage.getItem("token");
        axios
          .get(
            `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/idea/accept/${item.teamId}`,

            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {})
          .catch((error) => {
            console.error("Error making the initial request:", error);
          });

        return { ...item, status: "ACCEPTED" };
      }
      return item;
    });
    setIdeaSub(updatedData);
    handleCloseModal(true);
  };

  const handleReject = (itemIndex) => {
    const updatedData = ideasub?.map((item, index) => {
      if (item.teamId === itemIndex) {
        const jwtToken = localStorage.getItem("token");
        axios
          .get(
            `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/idea/reject/${item.teamId}`,

            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {})
          .catch((error) => {
            console.error("Error making the initial request:", error);
          });

        return { ...item, status: "Online" };
      }
      return item;
    });
    //("hggyujg", updatedData);

    setIdeaSub(updatedData);
    //(currentData);
    handleCloseModal(true);
  };

  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/ideas/all`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setIdeaSub(res?.data);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);
  const filteredData = ideasub.filter((item) =>
    item?.team?.teamname.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const currentData = filteredData.slice(startIndex, endIndex);

  const downloadCSV = () => {
    // Helper function to flatten nested objects into a string
    const flattenObject = (obj, parentKey = "") => {
      const flattened = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const newKey = parentKey ? `${parentKey}.${key}` : key;
          if (
            typeof obj[key] === "object" &&
            obj[key] !== null &&
            !Array.isArray(obj[key])
          ) {
            Object.assign(flattened, flattenObject(obj[key], newKey));
          } else if (Array.isArray(obj[key])) {
            flattened[newKey] = obj[key]
              .map((item) => JSON.stringify(item))
              .join("; ");
          } else {
            flattened[newKey] = obj[key];
          }
        }
      }
      return flattened;
    };

    // Flatten all objects in the ideasub array
    const flattenedData = ideasub.map((item) => flattenObject(item));

    // Extract column names from the first flattened item
    const columns = Object.keys(flattenedData[0]);

    // Convert JSON data to CSV format with column names
    const csvContent =
      columns.join(",") +
      "\n" +
      flattenedData
        .map((item) => {
          const rowData = columns.map((column) => {
            // Escape any quotes in the data
            const value =
              item[column] !== undefined
                ? `"${String(item[column]).replace(/"/g, '""')}"`
                : '""';
            return value;
          });
          return rowData.join(",");
        })
        .join("\n");

    // Create a Blob object and trigger download
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = "ideasub.csv";
    document.body.appendChild(link); // Needed for Firefox
    link.click();
    document.body.removeChild(link); // Clean up
  };

  // const currentData = filteredData?.slice(startIndex, endIndex);
  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(filteredData?.length / entriesPerPage);
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div className="team-details">
      <h1>Idea Submission</h1>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="search-bar"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <i className="fa fa-search" aria-hidden="true"></i>
          <input
            placeholder="Search for team name"
            value={searchQuery}
            onChange={handleSearchChange}
          />{" "}
        </div>
        <div
          className="download-button2"
          style={{ marginTop: "0rem", marginLeft: "2rem" }}
        >
          <button onClick={downloadCSV}>Download CSV</button>
        </div>
      </div>
      <div className="team-table">
        <table className="table-container">
          <thead>
            <tr>
              <th>SNo.</th>
              <th>Team Name</th>
              <th>Team Leader</th>
              <th>Member Count</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            {currentData?.map((item, index) => {
              const actualIndex = index + startIndex;
              return (
                <tr
                  key={index}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <td className="value">{actualIndex + 1}</td>
                  <td className="value">{item?.team.teamname}</td>
                  <td className="value">{item?.team?.team_leader.name}</td>
                  <td className="value">{item?.team?.member_count}</td>
                  <td
                    className={`status ${getStatusClassName(
                      item.status?.toUpperCase()
                    )}`}
                  >
                    {item.status}
                  </td>
                  <td
                    className="show-details-button"
                    onClick={() => handleShowDetails(item)}
                  >
                    Show Details
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="previous-btn"
          disabled={currentPage === 1}
          onClick={handlePreviousPage}
        >
          Previous
        </button>
        <span
          className="editable-span"
          contentEditable={true}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              const newPage = parseInt(e.currentTarget.textContent);
              handlePageChange(newPage);
              e.currentTarget.blur(); // Remove focus after Enter is pressed
            }
          }}
          onBlur={(e) => {
            const newPage = parseInt(e.currentTarget.textContent);
            handlePageChange(newPage);
          }}
        >
          {currentPage}
        </span>
        <span>/ {Math.ceil(filteredData?.length / entriesPerPage)}</span>
        <button
          className="next-btn"
          disabled={endIndex >= ideasub.length}
          onClick={handleNextPage}
        >
          Next
        </button>
      </div>
      {selectedItem && (
        <div className="modal-overlay" style={{ height: "90vh" }}>
          <div className="modal">
            <div className="modal-header">
              <h2>Team Details</h2>
              <span className="modal-close-inside" onClick={handleCloseModal}>
                &times;
              </span>
            </div>
            <div className="details-table-container">
              <table className="details-table " style={{}}>
                <tbody className="tbody-details">
                  <tr className="trtrt">
                    <td className="tdth" style={{ border: "none" }}>
                      Q. Briefly describe your Team
                    </td>
                    <td
                      className="tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      A. {selectedItem.teamDescription}
                    </td>
                  </tr>
                  <tr className="trtrt">
                    <td className="tdth" style={{ border: "none" }}>
                      {" "}
                      Q. Have you ever collaborated on a project with a team? If
                      so, what was your role?
                    </td>
                    <td
                      className="idea-description tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      A. {selectedItem.collaborationExperience}
                    </td>
                  </tr>
                  <tr className="trtrt">
                    <td className="tdth" style={{ border: "none" }}>
                      {" "}
                      Q. Have you ever participated/won in a hackathon before?
                      If yes, please specify your journey.
                    </td>
                    <td
                      className="tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      A. {selectedItem.hackathonJourney}
                    </td>
                  </tr>
                  <tr className="trtrt">
                    <td className="tdth" style={{ border: "none" }}>
                      Q. How do you handle challenges and setbacks during a
                      project?
                    </td>
                    <td
                      className="tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      A. {selectedItem.handlingChallenges}
                    </td>
                  </tr>
                  <tr className="trtrt">
                    <td className="tdth" style={{ border: "none" }}>
                      Q. What do you hope to achieve by participating in Hack
                      The Mountains 5.0?
                    </td>
                    <td
                      className="tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      A. {selectedItem.hackTheMountainsGoals}
                    </td>
                  </tr>
                  <tr className="trtrt">
                    <td className="tdth" style={{ border: "none" }}>
                      Q. Any additional comments or specific needs your team
                      has?
                    </td>
                    <td
                      className="tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      A. {selectedItem.additionalCommentsOrNeeds}
                    </td>
                  </tr>
                  {/* <tr className="trtrt">
                    <td className="tdth" style={{ border: "none" }}>
                      Q. Do you understand and agree to the event rules and code
                      of conduct?
                    </td>
                    <td
                      className="tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      A. {selectedItem.rules}
                    </td>
                  </tr> */}
                  <tr className="trtrt">
                    <td
                      className="tdth"
                      style={{
                        border: "none",
                        borderTop: "solid #aaa 4px",
                        paddingTop: "20px",
                        marginRight: "100px",
                        marginTop: "20px",
                      }}
                    >
                      Status:
                    </td>
                    <td
                      className="tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      {selectedItem.status}
                    </td>
                  </tr>
                  {/* ... (add other rows for additional details) */}
                  <tr className="trtrt">
                    <td className="tdth" style={{ border: "none" }}>
                      Members:
                    </td>
                    <td
                      className="tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      {selectedItem.team.team_leader.name}
                      {selectedItem.team.members.map((member, index) => (
                        <div key={index}>{member.name}</div>
                      ))}
                    </td>
                  </tr>
                  <tr className="trtrt">
                    <td className="tdth" style={{ border: "none" }}>
                      Team Name:
                    </td>
                    <td
                      className="tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      {selectedItem.team.teamname}
                    </td>
                  </tr>
                  <tr className="trtrt">
                    <td className="tdth" style={{ border: "none" }}>
                      Team Leader Details:
                    </td>

                    <td
                      className="tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      Name: {selectedItem.team.team_leader.name} <br />
                      Email: {selectedItem.team.team_leader.email}
                      <br />
                      Phone:{selectedItem.team.team_leader.phone}
                      <br />
                      Bio: {selectedItem.team.team_leader.bio}
                      <br />
                      Address: {selectedItem.team.team_leader.address}
                      <br />
                      Age: {selectedItem.team.team_leader.age}
                      <br />
                      Country: {selectedItem.team.team_leader.country}
                      <br />
                      Course & Year: {selectedItem.team.team_leader.courseYear}
                      <br />T Shirt Size:{" "}
                      {selectedItem.team.team_leader?.tShirtSize}
                      <br />
                      {selectedItem.team.team_leader?.organization}
                    </td>
                  </tr>
                  <tr className="trtrt">
                    <td className="tdth" style={{ border: "none" }}>
                      Team Members Details:
                    </td>
                    <td
                      className="tdth"
                      style={{ color: "blueviolet", border: "none" }}
                    >
                      {selectedItem.team.members.map((member, index) => (
                        <div key={index}>
                          <span style={{ color: "black" }}>
                            Member {index + 1}
                          </span>{" "}
                          <br />
                          Name: {member.name} <br />
                          Email: {member.email}
                          <br />
                          Phone:{member.phone}
                          <br />
                          Bio: {member.bio}
                          <br />
                          Address: {member.address}
                          <br />
                          Age: {member.age}
                          <br />
                          Country: {member.country}
                          <br />
                          Course & Year: {member.courseYear}
                          <br />T Shirt Size: {member?.tShirtSize}
                          <br />
                          {member?.organization}
                          <br />
                          <br />
                        </div>
                      ))}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="status-buttons">
              <button
                className="approve-button"
                onClick={() => {
                  handleApprove(selectedItem.teamId);
                }}
              >
                Approve
              </button>

              <button
                className="reject-button"
                onClick={() => handleReject(selectedItem.teamId)}
              >
                Online
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function getStatusClassName(status) {
  switch (status) {
    case "ACCEPTED":
      return "status-approved";
    case "Online":
      return "status-not-approved";
    case "IN REVIEW":
      return "status-in-review";
    default:
      return "";
  }
}
