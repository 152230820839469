import React, { useState, useEffect } from "react";
import axios from "axios";
import "../assets/styles/form_submissions.css";
import { Link, Routes, Route, useNavigate } from "react-router-dom";
import { Country, State } from "country-state-city";
// import Welcome from "../pages/WelcomePage";
export default function Application(data) {
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  let countryData = Country.getAllCountries(); // Assuming this returns an array of arrays
  // // // //(countryData[229].name === "");
  //   const combinedArray = data.array1.concat(data.array2, data.array3);

  // // // // //(combinedArray);

  // // // // //(AllCountries);
  useEffect(() => {
    // Fetch countries using the library
    const countryData = Country.getAllCountries();
    setCountries(countryData);
  }, []);
  const [backButtonClicked, setBackButtonClicked] = useState(false);

  const [redirectData, setRedirectData] = useState(null);
  const [errorAge, setErrorAge] = useState("");
  const [states, setStates] = useState([]);
  const [sum, setSum] = useState("set");
  const [fin, setFin] = useState("fin");
  const id = data?.data;
  // // // // //(data?.data);
  // // // // // //("cjhsdijkh");
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue) && inputValue <= 9999999999) {
      setFormValues({
        ...formValues,
        c_phone: e.target.value,
      });
    }
  };
  const handleChangeford_age = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue) && inputValue <= 99) {
      setFormValues({ ...formValues, d_age: e.target.value });
      setErrorAge("");
    }
  };
  const handleChangeforPincode = (e) => {
    const inputValue = e.target.value;
    if (!isNaN(inputValue)) {
      setFormValues({ ...formValues, m_pincode: e.target.value });
    }
  };
  const [direct, setDirect] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [firstPage, setFirstPage] = useState("block");
  const [secondPage, setSecondPage] = useState("none");
  const [thirdPage, setThirdPage] = useState("none");
  const [fourthPage, setFourthPage] = useState("none");
  const [buttonText, setButtonText] = useState("NEXT");
  const navigate = useNavigate();
  const savedEmail = localStorage.getItem("email");
  const [contactNumberError, setContactNumberError] = useState("");
  const [formValues, setFormValues] = useState({
    aa_email: savedEmail,
    ac_name: "",
    c_phone: "",
    e_gender: "",
    d_age: "",
    h_course_year: "",
    h_levelOfStudy: "",
    i_organization: "",
    j_address: "",
    l_country: "",
    k_res_state: "",
    m_pincode: "",
    f_tShirtSize: "",
    n_bio: "",
    p_linkedin: "",
    q_twitter: "",
    o_github: "",
    r_consentToShare: false,
  });

  const proceed = () => {
    let isPageValid = false;

    if (currentPage === 1) {
      const {
        ac_name,
        c_phone,
        e_gender,
        d_age,
        h_course_year,
        h_levelOfStudy,
        i_organization,
      } = formValues;

      if (
        ac_name &&
        c_phone &&
        e_gender &&
        d_age &&
        h_course_year &&
        h_levelOfStudy &&
        i_organization
      ) {
        const numberValue = Number(d_age, 10);
        if (c_phone.length < 10) {
          isPageValid = false;
          setContactNumberError("Contact number should be up to 10 digits.");
        } else if (numberValue < 16) {
          isPageValid = false;
          setContactNumberError("Age should be more than 15.");
        } else if (d_age.length < 2) {
          isPageValid = false;
          setContactNumberError("Age should be more than 15.");
        } else if (h_levelOfStudy === "") {
          console.log({ h_levelOfStudy });
          isPageValid = false;
          setContactNumberError("Please select level of study.");
        } else {
          // // // // //(numberValue > 16);
          isPageValid = true;
          setContactNumberError("");
        }
        // isPageValid = true;
      }
    } else if (currentPage === 2) {
      const {
        j_address,
        l_country,
        k_res_state,
        m_pincode,
        f_tShirtSize,
        n_bio,
      } = formValues;

      if (
        j_address &&
        l_country &&
        k_res_state &&
        m_pincode &&
        f_tShirtSize &&
        n_bio
      ) {
        if (n_bio.length > 150) {
          isPageValid = false;
          setContactNumberError("Bio length should be upto 150 character.");
        } else {
          setContactNumberError("");
          isPageValid = true;
        }
      }
    } else if (currentPage === 3) {
      isPageValid = true; // Assuming the third pd_age does not have any required fields
    } else if (currentPage === 4) {
      isPageValid = true;
    } else {
      isPageValid = false;
    }

    if (isPageValid) {
      if (currentPage === 1) {
        setFirstPage("none");
        setCurrentPage(2);
        setSecondPage("block");
        setFin("fin");
      } else if (currentPage === 2) {
        setSecondPage("none");
        setCurrentPage(3);
        setThirdPage("block");
        setFin("fin");
      } else if (currentPage === 3) {
        setThirdPage("none");
        setCurrentPage(4);
        setFourthPage("block");
        setButtonText("SUBMIT");
        setFin("fin");
      } else if (currentPage === 4) {
        setFin("completed");
        setIsLoading(true);
      }
    }
  };

  const handleSelectChange = (e) => {
    if (e.target.value !== "Select your gender")
      setFormValues({ ...formValues, e_gender: e.target.value });
  };

  const handleLevelOfStudyChange = (e) => {
    if (e.target.value !== "Select your level of study")
      setFormValues({ ...formValues, h_levelOfStudy: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // setIsLoading(true);
    // // // // //(formValues);

    // // // // // //(
    //   "https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/api/user_reg/" +
    //     id
    // );
    if (buttonText === "SUBMIT" && secondPage === "none" && fin === "fin") {
      // // // // //("all check");
      // // // // //(currentPage);
      return;
    } else if (buttonText === "SUBMIT" && fin === "completed") {
      // // // // //(currentPage);
      // // // // //(secondPage);
      const jwtToken = localStorage.getItem("user");
      if (formValues.r_consentToShare === "")
        setFormValues({ ...formValues, r_consentToShare: false });
      axios
        .post(
          "https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/api/user_reg/" +
            id,
          formValues,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json", // Assuming you're sending JSON data in the request body
            },
          },
        )
        .then((res) => {
          // Handle the message in the React component
          // // // // //(res);
          const fetchData = async () => {
            try {
              const response = await axios.get(
                "https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod" +
                  res?.data?.redirectUrl,
                {
                  headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json", // Assuming you're sending JSON data in the request body
                  },
                },
              );
              // setDirect(response?.data?.redirect);
              window.location.replace(`/api${response?.data?.redirect}`);
              // navigate(`api/${response?.data?.redirect}`); // Update the component state with the response data
            } catch (error) {
              // console.error("Error fetching data:", error);
            }
          };

          fetchData();
          // navigate(`${response?.data?.redirectUrl}`);
          // navigate( navigate(`${response?.data?.redirectUrl}`))
        })
        .catch((error) => {
          // // // // //(error);
          // Handle error state
        });
    } else if (sum === "noset") {
      // If the current page is 3, navigate back to the previous page
      // // // // //("pppppp");
      return;
    } else if (buttonText === "NEXT") {
      return;
    } else {
      const jwtToken = localStorage.getItem("user");
      axios
        .post(
          `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/api/user_reg/` +
            id,
          formValues,
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json", // Assuming you're sending JSON data in the request body
            },
          },
        )
        .then((res) => {
          // Handle the message in the React component
          // // // // //(res);
          const fetchData = async () => {
            try {
              const response = await axios.get(
                "https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod" +
                  res?.data?.redirectUrl,
                {
                  headers: {
                    Authorization: `Bearer ${jwtToken}`,
                    "Content-Type": "application/json", // Assuming you're sending JSON data in the request body
                  },
                },
              );
              // setDirect(response?.data?.redirect);
              window.location.replace(`/api${response?.data?.redirect}`);
              // naviate(`api/${response?.data?.redirect}`); // Update the component state with the response data
            } catch (error) {
              // console.error("Error fetching data:", error);
            }
          };

          fetchData();
          // navigate(`${response?.data?.redirectUrl}`);
          // navigate( navigate(`${response?.data?.redirectUrl}`))
        })
        .catch((error) => {
          // // // // //(error);
          // Handle error state
        });
    }
    // setIsLoading(false);
  };
  const goBack = () => {
    setSum("noset");
    if (currentPage === 2) {
      setSecondPage("none");
      setCurrentPage(1);
      setFirstPage("block");
    } else if (currentPage === 3) {
      setThirdPage("none");
      setCurrentPage(2);
      setSecondPage("block");
      setButtonText("NEXT");
    } else if (currentPage === 4) {
      setFourthPage("none");
      setCurrentPage(3);
      setThirdPage("block");
      setButtonText("NEXT");
    }
    setBackButtonClicked(true);
  };
  const countriesToIgnore = ["North Korea", "Russia", "Cuba", "Iran", "Syria"];
  const filteredCountries = countries.filter(
    (country) => !countriesToIgnore.includes(country.name),
  );
  const handleCountryChange = (e) => {
    const countryName = e.target.value;
    const country = countries.find((c) => c.name === countryName);

    if (country) {
      const states = State.getStatesOfCountry(country.isoCode);
      setFormValues({
        ...formValues,
        l_country: countryName,
        k_res_state: "", // Reset state when country changes
      });
      setStates(states);
    }
  };
  function navigateToPage() {
    const desiredPageUrl = "/login"; // Replace with the URL of the page you want to navigate to
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("value");
    window.location.href = desiredPageUrl;
  }

  // Add an event listener for the popstate event
  window.addEventListener("popstate", (event) => {
    // Execute the function to navigate the user to the desired page
    navigateToPage();
  });

  // Push a new state to the history when the user lands on the current page
  // This will ensure that there's always a state in the history stack for this page
  window.history.pushState({ page: "current" }, null, window.location.href);
  window.history.pushState({ page: "current" }, null, window.location.href);
  const handleFormKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission
    }
  };
  useEffect(() => {
    document.title = "Registration HTM";
    const favicon = document.getElementById("favicon");
    favicon.href =
      "https://htm-4-images.s3.amazonaws.com/img/HTMLOGHO_%20NEW.png";
    return () => {
      document.title = "Portal | HTM";
    };
  }, []);
  //
  return (
    <>
      {/* {// // // //(formValues)} */}
      <div className="proper-box">
        <div className="application-box" onKeyDown={handleFormKeyPress}>
          <h1>REGISTRATION</h1>
          <form onSubmit={handleSubmit}>
            {/* First Pd_age */}
            <div style={{ display: firstPage }}>
              <input
                type="text"
                className="app-input-big"
                placeholder="Full Name"
                value={formValues.ac_name}
                onChange={(e) =>
                  setFormValues({ ...formValues, ac_name: e.target.value })
                }
                required
              />
              <div className="two-input">
                <input
                  // type="number"
                  type="text"
                  id="numberInput"
                  // pattern="[0-9]{10}"
                  className="app-input"
                  placeholder="Contact Number"
                  value={formValues.c_phone}
                  onChange={handleChange}
                  required
                  min="11111111111"
                />
                <select
                  className={`${
                    formValues.e_gender === ""
                      ? "input-country-default"
                      : "input-country"
                  }`}
                  name="gender"
                  id="gender"
                  value={formValues.e_gender}
                  onChange={handleSelectChange}
                  required
                  defaultValue={"DEFAULT"}
                >
                  <option value="DEFAULT" selected>
                    Select your gender
                  </option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="non-binary">Non-binary</option>
                  <option value="other">Other</option>
                  <option value="prefer not to say">Prefer not to say</option>
                </select>
              </div>
              <div className="two-input">
                <input
                  type="text"
                  className="app-input"
                  placeholder="Age"
                  value={formValues.d_age}
                  onChange={handleChangeford_age}
                  min="15"
                  required
                />
                <input
                  type="text"
                  className="app-input margined-input"
                  placeholder="Course & Graduation Year"
                  value={formValues.h_course_year}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      h_course_year: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div className="two-input">
                <select
                  className={`${
                    formValues.h_levelOfStudy === ""
                      ? "input-country-default"
                      : "input-country"
                  }`}
                  name="levelOfStudy"
                  id="levelOfStudy"
                  style={{ marginBottom: "20px" }}
                  value={formValues.h_levelOfStudy}
                  onChange={handleLevelOfStudyChange}
                  required
                  defaultValue={"DEFAULT"}
                >
                  <option value="DEFAULT" selected>
                    Select your level of study
                  </option>
                  <option value="Less than Secondary / High School">
                    Less than Secondary / High School
                  </option>
                  <option value="Secondary / High School">
                    Secondary / High School
                  </option>
                  <option value="Undergraduate University (2 year - community college or similar)">
                    Undergraduate University (2 year - community college or
                    similar)
                  </option>
                  <option value="Undergraduate University (3+ year)">
                    Undergraduate University (3+ year)
                  </option>
                  <option value="Graduate University (Masters, Professional, Doctoral, etc)">
                    Graduate University (Masters, Professional, Doctoral, etc)
                  </option>
                  <option value="Code School / Bootcamp">
                    Code School / Bootcamp
                  </option>
                  <option value="Other Vocational / Trade Program or Apprenticeship">
                    Other Vocational / Trade Program or Apprenticeship
                  </option>
                  <option value="Post Doctorate">Post Doctorate</option>
                  <option value="Other">Other</option>
                  <option value="I’m not currently a student">
                    I’m not currently a student
                  </option>
                  <option value="Prefer not to say">Prefer not to say</option>
                </select>
                <input
                  type="text"
                  className="app-input"
                  style={{ marginBottom: "20px" }}
                  placeholder="Organization / College Name"
                  value={formValues.i_organization}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      i_organization: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            {/* {// // // // //(errorAge)} */}
            {/* {errorAge && <h5>{errorAge}</h5>} */}
            {/* Second Pd_age */}
            <div style={{ display: secondPage }}>
              <input
                type="text"
                className="app-input-big"
                placeholder="Please enter your full address"
                value={formValues.j_address}
                onChange={(e) =>
                  setFormValues({ ...formValues, j_address: e.target.value })
                }
                required
              />
              <div className="two-input">
                <select
                  // type="text"
                  className={`${
                    formValues.l_country === ""
                      ? "input-country-default"
                      : "input-country"
                  }`}
                  name="l_country"
                  id="l_country"
                  placeholder="Country"
                  value={formValues.l_country}
                  // onChange={(e) =>
                  //   setFormValues({ ...formValues, l_country: e.target.value })
                  // }
                  onChange={handleCountryChange}
                  required
                >
                  <option value="">Select a country</option>
                  {filteredCountries.map((country) => (
                    <option key={country.code} value={country.name}>
                      {country.name}
                    </option>
                  ))}
                  {/* </select> */}
                </select>

                <select
                  className={`${
                    formValues.k_res_state === ""
                      ? "input-country-default"
                      : "input-country"
                  }`}
                  name="k_res_state"
                  id="k_res_state"
                  placeholder="State"
                  value={formValues.k_res_state}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      k_res_state: e.target.value,
                    })
                  }
                  required
                >
                  <option value="">Select a state</option>
                  {states?.map((state) => (
                    <option key={state.isoCode} value={state.name}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="two-input">
                <input
                  type="text"
                  className="app-input margined-input"
                  placeholder="Pincode"
                  value={formValues.m_pincode}
                  onChange={handleChangeforPincode}
                  required
                />
                <select
                  className={`${
                    formValues.f_tShirtSize === ""
                      ? "input-country-default"
                      : "input-country"
                  }`}
                  name="tShirtSize"
                  id="tShirtSize"
                  value={formValues.f_tShirtSize}
                  onChange={(e) =>
                    setFormValues({
                      ...formValues,
                      f_tShirtSize: e.target.value,
                    })
                  }
                  required
                >
                  <option value="" disabled>
                    T-Shirt Size
                  </option>
                  <option value="S">S</option>
                  <option value="M">M</option>
                  <option value="L">L</option>
                  <option value="XL">XL</option>
                  <option value="XXL">XXL</option>
                </select>
              </div>
              <input
                type="text"
                className="app-input-big"
                style={{ marginBottom: "20px" }}
                placeholder="Bio"
                value={formValues.n_bio}
                onChange={(e) =>
                  setFormValues({ ...formValues, n_bio: e.target.value })
                }
                // minLength={100}
                required
              />
            </div>
            {/* Third Pd_age */}
            <div
              style={{ display: thirdPage, marginBottom: "20px" }}
              className="thirdPage"
            >
              <input
                type="text"
                className="app-input-big"
                placeholder="LinkedIn URL *"
                // required
                id="linkedin"
                name="linkedin"
                value={formValues.p_linkedin}
                onChange={(e) =>
                  setFormValues({ ...formValues, p_linkedin: e.target.value })
                }
                pattern="^(https?://)?([a-z]{2,3}\.)?linkedin\.com/.*$"
                required
              />
              {formValues.p_linkedin &&
                !/^(https?:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/.*$/.test(
                  formValues.p_linkedin,
                ) && <p className="error-message">Invalid LinkedIn URL</p>}
              <input
                type="text"
                className="app-input-big"
                value={formValues.o_github}
                placeholder="GitHub URL *"
                name="github"
                id="github"
                pattern="^(https?://)?(www\.)?github\.com/.*$"
                onChange={(e) =>
                  setFormValues({ ...formValues, o_github: e.target.value })
                }
                required
              />
              {formValues.o_github &&
                !/^(https?:\/\/)?(www\.)?github\.com\/.*$/.test(
                  formValues.o_github,
                ) && <p className="error-message">Invalid GitHub URL</p>}
              <input
                type="text"
                className="app-input-big"
                placeholder="X URL"
                value={formValues.q_twitter}
                id="twitter"
                name="twitter"
                onChange={(e) =>
                  setFormValues({ ...formValues, q_twitter: e.target.value })
                }
                pattern="^(https?://)?(www\.)?x\.com/.*$"
              />
              {formValues.q_twitter &&
                !/^(https?:\/\/)?(www\.)?(x\.com|twitter\.com)\/.*$|^$/.test(
                  formValues.q_twitter,
                ) && <p className="error-message">Invalid X URL</p>}
            </div>
            <div style={{ display: fourthPage }} className="fourthPage">
              <span
                style={{
                  // give it very minimal text sizes and line spacing
                  fontSize: "1rem",
                  lineHeight: "1.5",
                  marginBottom: "20px",
                  textAlign: "left",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  marginLeft: "7px",
                  marginRight: "7px",
                  letterSpacing: "0px",
                }}
              >
                We are currently in the process of partnering with MLH. The
                following 3 checkboxes are for this partnership. If we do not
                end up partnering with MLH, your information will not be shared.
              </span>
              <div className="check-boxes">
                <label style={{ display: "flex", alignItems: "center" }}>
                  <input
                    style={{ aspectRatio: 1 }}
                    checked
                    disabled
                    type="checkbox"
                    required
                  />
                  <span className="check-terms">
                    I have read and agree to the MLH&nbsp;
                    <a
                      href="https://github.com/MLH/mlh-policies/blob/main/code-of-conduct.md"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "None" }}
                    >
                      Code of Conduct
                    </a>
                    .
                  </span>
                </label>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <input
                      type="checkbox"
                      required
                      checked
                      disabled
                      className="ci"
                    />
                  </div>
                  <span className="check-terms">
                    I authorize you to share my application/registration
                    information with Major League Hacking for event
                    administration, ranking, and MLH administration in-line with
                    the&nbsp;
                    <a
                      href="https://github.com/MLH/mlh-policies/blob/main/privacy-policy.md"
                      // href="https://github.com/MLH/mlh-policies/blob/main/contest-terms.md"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "None" }}
                    >
                      MLH Privacy Policy
                    </a>
                    .<br />I further agree to the terms of both the&nbsp;
                    <a
                      href="https://github.com/MLH/mlh-policies/blob/main/contest-terms.md"
                      // href="https://github.com/MLH/mlh-policies/blob/main/contest-terms.md"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "None" }}
                    >
                      MLH Contest Terms and Conditions&nbsp;
                    </a>
                    and the&nbsp;
                    <a
                      href="https://github.com/MLH/mlh-policies/blob/main/privacy-policy.md"
                      // href="https://github.com/MLH/mlh-policies/blob/main/contest-terms.md"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "None" }}
                    >
                      MLH Privacy Policy
                    </a>
                    .
                  </span>
                </label>
                <label style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <input
                      type="checkbox"
                      onChange={(value) => {
                        setFormValues({
                          ...formValues,
                          r_consentToShare: value.target.checked,
                        });
                      }}
                    />
                  </div>
                  <span className="check-terms">
                    I authorize MLH to send me occasional emails about relevant
                    events, career opportunities, and community announcements.
                  </span>
                </label>
              </div>
            </div>

            {contactNumberError && (
              <p
                className="error-message"
                style={{ color: "red", fontSize: "1rem" }}
              >
                {contactNumberError}
              </p>
            )}

            {currentPage > 1 && (
              <button className="next-button" onClick={goBack}>
                BACK
              </button>
            )}
            <button
              className="next-button"
              onClick={proceed}
              type="submit"
              disabled={
                (!/^(https?:\/\/)?(www\.)?(x\.com|twitter\.com)\/.*$|^$/.test(
                  formValues.q_twitter,
                ) ||
                  !/^(https?:\/\/)?(www\.)?github\.com\/.*$/.test(
                    formValues.o_github,
                  ) ||
                  !/^(https?:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/.*$/.test(
                    formValues.p_linkedin,
                  )) &&
                currentPage === 3
              }
              style={
                (!/^(https?:\/\/)?(www\.)?(x\.com|twitter\.com)\/.*$|^$/.test(
                  formValues.q_twitter,
                ) ||
                  !/^(https?:\/\/)?(www\.)?github\.com\/.*$/.test(
                    formValues.o_github,
                  ) ||
                  !/^(https?:\/\/)?([a-z]{2,3}\.)?linkedin\.com\/.*$/.test(
                    formValues.p_linkedin,
                  )) &&
                currentPage === 3
                  ? { cursor: "not-allowed" }
                  : { cursor: "pointer" }
              }
            >
              {isLoading ? (
                <span className="loading-circle" /> // Add a rotating circle (CSS animation)
              ) : (
                buttonText
              )}
            </button>
            {/* {// // // // //(currentPage)} */}
          </form>
        </div>
      </div>
    </>
  );
}

// useEffect(() => {
//   // Replace with the actual ID
//   const jwtToken = localStorage.getItem("token");

//   axios
//     .get(
//       `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/teams`,
//       {
//         headers: {
//           Authorization: `Bearer ${jwtToken}`,
//           "Content-Type": "application/json",
//         },
//       }
//     )
//     .then((res) => {
//       //(res?.data);
//       // setTeamsData(res?.data);
//       //(teamsData);
//     })
//     .catch((error) => {
//       console.error("Error making the initial request:", error);
//     });
// }, []);

// const [teamsData, setTeamsData] = useEffect([]);
