import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import bg from "../assets/images/background_1.png";
import LoginComponent from "../components/AdminLogin";
import CheckLogin from "./CheckLogin";
function AdminLoginPage() {
  const User = localStorage.getItem("user");
  //(User);
  return (
    <>
      {User === null ? (
        <>
          <div
            className="wrapper"
            style={{
              background: "linear-gradient(135deg, #588380, #2f4443)",
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Navbar />
            <LoginComponent />
          </div>
        </>
      ) : (
        <>
          <div
            className="wrapper"
            style={{
              background: "linear-gradient(135deg, #588380, #2f4443)",
              height: "100vh",
              overflow: "auto",
            }}
          >
            <Navbar />
            <LoginComponent />
          </div>
        </>
        // <CheckLogin />
      )}
    </>
  );
}

export default AdminLoginPage;
