import React from "react";
import WelcomeComponent from "../components/Welcome";
import bg from "../assets/images/background_1.png";
import { useEffect, useState } from "react";
import NavbarUser from "../components/NavbarUser";
// import { useLocation, useNavigate } from "react-router-dom";
export default function Welcome() {
  // // //(location.state);

  return (
    <div
      className="wrapper"
      style={{
        background: "linear-gradient(135deg, #588380, #2f4443)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <NavbarUser />
      <WelcomeComponent />
    </div>
  );
}
