import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import RegisterComponent from "../components/Register";
import bg from "../assets/images/background_1.png";

function Register() {
  return (
    <div
      className="wrapper"
      style={{
        background: "linear-gradient(135deg, #588380, #2f4443)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <Navbar />
      <RegisterComponent />
    </div>
  );
}

export default Register;
