import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import { Link } from "react-router-dom";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

import "../assets/styles/user_onboarding_flow.css";
import axios from "axios";
const LoginComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [aa_email, setaa_email] = useState("");
  const [resetTitle, setResetTitle] = useState("RESET PASSWORD");
  const [modalHeight, setModalHeight] = useState("310px");
  const [timerEnd, setTimerEnd] = useState("none");
  const [resetMessage, setResetMessage] = useState(
    "Enter the email address for the account you wish to reset the password for",
  );
  const [resetFormVisible, setResetFormVisible] = useState(true);
  const [timer, setTimer] = useState(0);

  const openModal = () => {
    setModalIsOpen(true);
    setaa_email("");
    setResetTitle("RESET PASSWORD");
    setResetMessage(
      "Enter the email address for the account you wish to reset the password for",
    );
    setTimerEnd("none");

    setResetFormVisible(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setaa_email("");
    setModalHeight("310px");
    setTimer(0); // Reset timer when closing the modal
  };
  function formatTime(seconds) {
    if (seconds === parseInt(seconds, 10)) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(remainingSeconds).padStart(2, "0");

      return `${formattedMinutes}:${formattedSeconds}`;
    } else {
      return seconds;
    }
  }
  const handleChange = (e) => {
    const { value } = e.target;
    setaa_email(value);
  };

  const isValidEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  const sendEmail = () => {
    // // // //(aa_email);
    axios
      .post(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/reset-password-email`,
        {
          aa_email,
        },
      )
      .then((res) => {
        // Handle the message in the React component
        // // // //(res);
        // navigate(`${response?.data?.redirectUrl}`);
        // navigate( navigate(`${response?.data?.redirectUrl}`))
      })
      .catch((error) => {
        // // // //(error);
        // Handle error state
      });
    setResetTitle("CHECK EMAIL");
    setResetMessage(
      `A password reset link has been sent to <span style='color: red'>${aa_email}</span>.`,
    );
    setResetFormVisible(false);
    setModalHeight("190px");

    // Start the timer when the email is sent
    setTimer(30); // Set the initial value of the timer (60 seconds)
  };
  useEffect(() => {
    // Function to clear the error after 3 seconds
    const clearError = () => {
      setError("");
    };

    // If there is an error, set a timeout to clear it after 3 seconds
    if (error) {
      const timeout = setTimeout(clearError, 3000);
      return () => clearTimeout(timeout);
    }
  }, [error]);
  useEffect(() => {
    let interval;
    if (timer > 0) {
      setTimerEnd("none");

      // Start the interval to decrement the timer
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000); // 1000 milliseconds = 1 second
    } else if (timer === 0) {
      // // // //("Timer hit 0.");
      setTimerEnd("block");
    }

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [timer]);
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (!isValidEmail(email)) {
      setError("Please enter a valid email address");
      setTimeout(() => {
        setError(null);
      }, 3000);
      setIsLoading(false);
      return;
    }
    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      setTimeout(() => {
        setError(null);
      }, 3000); // Clear error message after 3 seconds
      setIsLoading(false);
      return;
    }

    const data = {
      aa_email: email,
      ab_password: password,
    };

    axios
      .post(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/login`,
        data,
      )
      .then((response) => {
        // // // //(response);
        // Assuming the response.data contains the user data and token
        const user = response.data;
        setUser(user);
        //(user.token);
        localStorage.setItem("user", user?.token);
        localStorage.setItem("email", email);
        localStorage.setItem("name", user?.name);

        let redirectUrl = "/";

        if (user?.redirectUrl) {
          redirectUrl = user.redirectUrl;
        }

        window.location.replace(`${redirectUrl}`);
        setEmail("");
        setPassword("");
      })
      .catch((error) => {
        // // // //(error);
        setError(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  useEffect(() => {
    document.title = "Login HTM";

    return () => {
      document.title = "Portal | HTM";
    };
  }, []);
  // window.onbeforeunload = function () {
  //   localStorage.removeItem("user");
  //   return;
  // };
  const User = localStorage.getItem("user");
  return (
    <>
      {/* process.env.React_App_BASE_URL */}
      <div className="onboard-box">
        <h1>LOGIN</h1>
        <form onSubmit={handleSubmit}>
          <input
            required
            type="email"
            name="email"
            value={email}
            onChange={handleChangeEmail}
            id="email-log"
            placeholder="Enter Your Email"
            className="onboard-box-input"
          />

          <div className="password-input-container">
            <input
              required
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              value={password}
              onChange={handleChangePassword}
              placeholder="Enter Your Password"
              className="onboard-box-input2"
            />
            {password && (
              <div
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
              </div>
            )}
          </div>
          <button id="forgot" type="button" onClick={openModal}>
            Forgot Password?
          </button>
          {error && (
            <h4 style={{ color: "red" }} className="error-in">
              {error}
            </h4>
          )}
          <button id="log">
            {isLoading ? (
              <span className="loading-circle" /> // Add a rotating circle (CSS animation)
            ) : (
              "LOGIN"
            )}
          </button>
        </form>

        <p>
          Not Registered ? Click here to <Link to="/register">Register</Link>
        </p>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Reset Password Modal"
        style={{ content: { borderRadius: "20px", height: modalHeight } }}
      >
        <div className="modal-top">
          <h1 id="reset-title">{resetTitle}</h1>
          <button id="reset-button" onClick={closeModal}>
            <ImCross />
          </button>
        </div>
        <p id="reset-p" dangerouslySetInnerHTML={{ __html: resetMessage }} />
        {resetFormVisible && (
          <form id="reset-form">
            <input
              onChange={handleChange}
              value={aa_email}
              className="email-input"
              type="email"
              name="email"
              id="email-res"
              autoComplete="off"
              placeholder="Enter Your Email"
            />
            <br />
            <button
              className="submit-button"
              type="button"
              onClick={sendEmail}
              disabled={!isValidEmail(aa_email)}
            >
              SUBMIT
            </button>
          </form>
        )}
        {timer > 0 && <div className="timer">{formatTime(timer)}</div>}
        <div className="reset">
          <div className="timer" style={{ display: timerEnd }}>
            <button className="resend" onClick={sendEmail}>
              Resend
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LoginComponent;
