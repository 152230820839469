import { useEffect, useState } from "react";
import NavbarUser from "../components/Error404";
import bg from "../assets/images/background_1.png";
import Error from "../components/Error";
const ErrorPage = () => {
  return (
    <div
      className="wrapper"
      style={{
        background: "linear-gradient(135deg, #588380, #2f4443)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <NavbarUser />
      <Error />
    </div>
  );
};

export default ErrorPage;
