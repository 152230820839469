import React, { useState, useEffect } from "react";
import TagsInput from "./TagsInput";
import BackButton from "./BackButton";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
const IdeaSubmissionForm = () => {
  const params = useParams();
  const id = params?.id;

  const [error, setError] = useState();
  // const [formValues, setFormValues] = useState({});
  const [value, setValue] = useState("notSubmitted");
  const [formSubmissions, setFormSubmissions] = useState([]);
  const [formValues, setFormValues] = useState({
    rules: "",
    teamDescription: "",
    collaborationExperience: "",
    hackathonJourney: "",
    handlingChallenges: "",
    hackTheMountainsGoals: "",
    additionalCommentsOrNeeds: "",
    status: "IN REVIEW",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    const submissionData = { ...formValues };
    // (submissionData);
    // //(formValues);
    if (
      !formValues.rules ||
      !formValues.teamDescription ||
      !formValues.collaborationExperience ||
      !formValues.hackathonJourney ||
      !formValues.handlingChallenges ||
      !formValues.hackTheMountainsGoals
    ) {
      setError("All fields are required");
      return;
    }

    const data = {
      status: formValues.status,
      rules: formValues.rules,
      teamDescription: formValues.teamDescription,
      collaborationExperience: formValues.collaborationExperience,
      hackathonJourney: formValues.hackathonJourney,
      handlingChallenges: formValues.handlingChallenges,
      hackTheMountainsGoals: formValues.hackTheMountainsGoals,
      additionalCommentsOrNeeds: formValues.additionalCommentsOrNeeds,
    };

    const jwtToken = localStorage.getItem("user");
    axios
      .post(
        "https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/api/idea/" +
          id,
        data,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        localStorage.setItem("value", "Submitted");
        window.location.replace(ParseData?.key3);
      })
      .catch((error) => {
        setError(error?.respons.data?.error);
      });
    setTimeout(() => {
      setError(null);
    }, 4000);
  };
  const Tag = (data) => {
    // (typeof data);
    if (data && typeof data === "string") {
      return data.split(", ").map((track) => track.trim());
    } else {
      console.error("Invalid data for splitting:", data);
      return [];
    }
  };

  const [ParseData, setParseData] = useState();

  useEffect(() => {
    // Get the data parameter from the URL query string

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const encodedData = urlParams.get("data");

    if (encodedData) {
      try {
        const decodedData = atob(encodedData); // Decode base64
        const parsedData = JSON.parse(decodedData); // Parse JSON
        setParseData(parsedData); // Output: { key1: "value1", key2: "value2" }
        // Now you can use the parsedData object in your component
      } catch (error) {
        console.error("Error decoding data:", error);
      }
    }
    const jwtToken = localStorage.getItem("user");
    const isCheck = localStorage.getItem("value");
    if (isCheck === "Submitted") {
      const fetchData = async () => {
        try {
          const response = await axios.get(
            `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/api/idea/` +
              params?.id,
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
                "Content-Type": "application/json", // Assuming you're sending JSON data in the request body
              },
            },
          );
          //(response?.data);
          // (response);
          setFormValues({
            teamDescription: response?.data?.teamDescription,
            collaborationExperience: response?.data?.collaborationExperience,
            hackathonJourney: response?.data?.hackathonJourney,
            handlingChallenges: response?.data?.handlingChallenges,
            hackTheMountainsGoals: response?.data?.hackTheMountainsGoals,
            additionalCommentsOrNeeds:
              response?.data?.additionalCommentsOrNeeds,
            rules: response?.data?.rules,
            status: response?.data?.status,
          });
          // setDirect(response?.data?.redirect);
          // window.location.replace(`/api${response?.data?.redirect}`);
          // navigate(`api/${response?.data?.redirect}`); // Update the component state with the response data
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, []);
  const handleFormKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent default form submission
    }
  };
  function navigateToPage() {
    const desiredPageUrl = "/login"; // Replace with the URL of the page you want to navigate to
    window.location.href = desiredPageUrl;
  }

  // Add an event listener for the popstate event
  window.addEventListener("popstate", (event) => {
    // Execute the function to navigate the user to the desired page
    navigateToPage();
  });

  // Push a new state to the history when the user lands on the current page
  // This will ensure that there's always a state in the history stack for this page
  window.history.pushState({ page: "current" }, null, window.location.href);
  function navigateToPage() {
    const desiredPageUrl = "/login"; // Replace with the URL of the page you want to navigate to
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("value");
    window.location.href = desiredPageUrl;
  }

  // Add an event listener for the popstate event
  window.addEventListener("popstate", (event) => {
    // Execute the function to navigate the user to the desired page
    navigateToPage();
  });

  // Push a new state to the history when the user lands on the current page
  // This will ensure that there's always a state in the history stack for this page
  window.history.pushState({ page: "current" }, null, window.location.href);
  window.history.pushState({ page: "current" }, null, window.location.href);
  const [trackArray, setTrackArray] = useState([]);
  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/stats`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        // conso(res);
        // (res);
        const tracksString = res?.data[0]?.tracks;
        // conso(tracksString);

        if (tracksString) {
          const tracksArray = tracksString
            .split(",")
            .map((track) => track.trim());
          setTrackArray(tracksArray);
        }
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);
  return (
    <div className="idea-main">
      <div className="idea-form" onKeyDown={handleFormKeyPress}>
        <form onSubmit={handleSubmit}>
          <div className="field-wrap-idea-submission">
            <div>
              <BackButton url={ParseData?.key3} />
            </div>
            <div>
              <h1>Team Evaluation Form</h1>
            </div>
            <div> </div>
            {/* <div className="hid">IDEA SUB</div> */}
          </div>
          <div className="field-wrap">
            <label className="required">
              Briefly describe your Team (Max Limit 150 characters)
            </label>
            <input
              type="text"
              name="teamDescription"
              className="yash"
              required
              placeholder="Our team consists of 4 members with diverse..."
              value={formValues.teamDescription}
              onChange={(e) =>
                e.target.value.length <= 150
                  ? setFormValues({
                      ...formValues,
                      teamDescription: e.target.value,
                    })
                  : null
              }
            />
          </div>

          <div className="field-wrap">
            <label className="required">
              Have you ever collaborated on a project with a team? If so, what
              was your role? (Max 250 words)
            </label>
            <textarea
              name="collaborationExperience"
              rows="4"
              style={{ boder: "2px solid red" }}
              required
              placeholder="I have collaborated on a project with..."
              value={formValues.collaborationExperience}
              onChange={(e) =>
                e.target.value.length <= 250
                  ? setFormValues({
                      ...formValues,
                      collaborationExperience: e.target.value,
                    })
                  : null
              }
            ></textarea>
          </div>

          <div className="field-wrap">
            <label className="required">
              Have you ever participated/won in a hackathon before? If yes,
              please specify your journey. (Max 300 words)
            </label>
            <input
              style={{ boder: "2px solid red" }}
              type="text"
              placeholder="I have participated in a hackathon before..."
              required
              className="yash"
              name="hackathonJourney"
              value={formValues.hackathonJourney}
              onChange={(e) =>
                e.target.value.length <= 300
                  ? setFormValues({
                      ...formValues,
                      hackathonJourney: e.target.value,
                    })
                  : null
              }
            />
          </div>

          <div className="field-wrap">
            <label className="required">
              How do you handle challenges and setbacks during a project?
            </label>
            <input
              type="text"
              style={{ boder: "2px solid red" }}
              name="handlingChallenges"
              className="yash"
              placeholder="I handle challenges by..."
              required
              value={formValues.handlingChallenges}
              onChange={(e) =>
                e.target.value.length <= 500
                  ? setFormValues({
                      ...formValues,
                      handlingChallenges: e.target.value,
                    })
                  : null
              }
            />
          </div>

          <div className="field-wrap">
            <label className="required">
              What do you hope to achieve by participating in Hack The Mountains
              5.0?
            </label>
            <input
              type="text"
              style={{ boder: "2px solid red" }}
              name="hackTheMountainsGoals"
              className="yash"
              placeholder="I hope to achieve..."
              required
              value={formValues.hackTheMountainsGoals}
              onChange={(e) =>
                e.target.value.length <= 500
                  ? setFormValues({
                      ...formValues,
                      hackTheMountainsGoals: e.target.value,
                    })
                  : null
              }
            />
          </div>
          <div className="field-wrap">
            <label className="">
              Any additional comments or specific needs your team has?
            </label>
            <input
              type="text"
              style={{ boder: "2px solid red" }}
              name="additionalCommentsOrNeeds"
              className="yash"
              placeholder="I have dietary restrictions that organizers should be aware of for any provided meals during the event."
              value={formValues.additionalCommentsOrNeeds}
              onChange={(e) =>
                e.target.value.length <= 500
                  ? setFormValues({
                      ...formValues,
                      additionalCommentsOrNeeds: e.target.value,
                    })
                  : null
              }
            />
          </div>
          <div className="field-wrap">
            <label className="required">
              Do you understand and agree to the event rules and code of
              conduct?
            </label>
            <div className="option-buttons">
              <div className="button-container">
                <button
                  type="button"
                  value="Yes"
                  onClick={() => setFormValues({ ...formValues, rules: true })}
                  className={formValues.rules === "Yes" ? "active" : ""}
                >
                  Yes
                </button>
              </div>
              <div className="button-container">
                <button
                  type="button"
                  value="No"
                  onClick={() => setFormValues({ ...formValues, rules: false })}
                  className={formValues.rules === "No" ? "active" : ""}
                >
                  No
                </button>
              </div>
            </div>
          </div>

          {error && (
            <center>
              {" "}
              <p style={{ color: "red" }}>{error}</p>
            </center>
          )}
          <div className="ideaidea">
            <button
              type="button"
              onClick={handleSubmit}
              className="ideanext-button"
            >
              SUBMIT
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default IdeaSubmissionForm;
