import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "../assets/styles/register_user_flow.css";
import axios from "axios";

const JoinComponent = (data) => {
  const params = useParams();
  // // //(params?.id);
  const [joinErrorMessage, setJoinErrorMessage] = useState("");
  const [team_code, setteam_code] = useState("");
  // const [, setAAEmail] = useState(null);
  // useEffect(() => {
  //   const email = localStorage.getItem("email");
  //   setAAEmail(email);
  // }, []);
  const handleJoinChange = (event) => {
    setteam_code(event.target.value);
    setJoinErrorMessage("");
  };
  const checkTeamCode = () => {
    if (team_code === "") {
      setJoinErrorMessage("Please enter a valid team code");
      setTimeout(() => {
        setJoinErrorMessage("");
      }, 20000);
    } else if (!team_code.match(/^[A-Za-z0-9]{6}$/)) {
      setJoinErrorMessage(
        "Please enter a valid 6-digit alphanumeric team code",
      );
      setTimeout(() => {
        setJoinErrorMessage("");
      }, 20000);
    } else {
      // Backend logic for joining team goes here
      // // //("Joining team with code: " + team_code);
      const jwtToken = localStorage.getItem("user");
      const aa_email = localStorage.getItem("email");
      axios
        .post(
          `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/api/join_team/` +
            params?.id,
          // { aa_email: aa_email },
          { team_code, aa_email },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
              "Content-Type": "application/json", // Assuming you're sending JSON data in the request body
            },
          },
        )
        .then((res) => {
          // Handle the message in the React component
          // // //(res);
          window.location.replace(`/api/teamPage/${team_code}/${params?.id}`);

          // navigate(`${response?.data?.redirectUrl}`);
          // navigate( navigate(`${response?.data?.redirectUrl}`))
        })
        .catch((error) => {
          // // //(error);
          setJoinErrorMessage(error?.response?.data?.error);
          // Handle error state
        });
    }
  };
  function navigateToPage() {
    const desiredPageUrl = "/login"; // Replace with the URL of the page you want to navigate to
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("value");
    window.location.href = desiredPageUrl;
  }

  // Add an event listener for the popstate event
  window.addEventListener("popstate", (event) => {
    // Execute the function to navigate the user to the desired page
    navigateToPage();
  });

  // Push a new state to the history when the user lands on the current page
  // This will ensure that there's always a state in the history stack for this page
  window.history.pushState({ page: "current" }, null, window.location.href);
  window.history.pushState({ page: "current" }, null, window.location.href);
  useEffect(() => {
    document.title = "Join Team";
    const favicon = document.getElementById("favicon");
    favicon.href =
      "https://htm-4-images.s3.amazonaws.com/img/HTMLOGHO_%20NEW.png";
    return () => {
      document.title = "Portal | HTM";
    };
  }, []);
  //
  return (
    <div className="user-app-box">
      <div className="user-container">
        <h1>JOIN TEAM</h1>

        <form autoComplete="off">
          <input
            type="text"
            id="teamName"
            name="teamName"
            placeholder="Enter the team code"
            required
            value={team_code}
            forgot
            onChange={handleJoinChange}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                event.preventDefault(); // Prevent the default form submission behavior
                checkTeamCode();
              }
            }}
          />
          <button type="button" onClick={checkTeamCode}>
            JOIN
          </button>
        </form>
        {joinErrorMessage && <p className="error-msg">{joinErrorMessage}</p>}
        <div className="prompt_c">
          Want to Create Team?{" "}
          <Link to={`/api/create/${params?.id}`}>Create Team</Link>
        </div>
      </div>
    </div>
  );
};

export default JoinComponent;
