import React from "react";
import IdeaSubmissionForm from "../components/IdeaForm";
import bg from "../assets/images/background_1.png";
import { useState, useEffect } from "react";
import NavbarUser from "../components/NavbarUser";
export default function IdeaComponent() {
  return (
    <div
      className="wrapper"
      style={{
        background: "linear-gradient(135deg, #588380, #2f4443)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <NavbarUser />
      <IdeaSubmissionForm />
    </div>
  );
}
