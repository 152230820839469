import { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";

import "./assets/styles/global.css";
import Login from "./pages/LoginPage";
import Register from "./pages/RegisterPage";
import Forgot from "./pages/ForgotPage";
import Welcome from "./pages/WelcomePage";
// import Create from "./pages/CreatePage";
import Join from "./pages/JoinPage";
import UserApplicationPage from "./pages/UserApplicationPage";
import IdeaComponent from "./pages/Idea";
import TeamComponent from "./pages/TeamPage";

import AdminHome from "./pages/AdminHome";
import VerifiedPage from "./pages/VerifiedPage";
import ErrorPage from "./pages/ErrorPage";
import Create from "./pages/CreatePage";
import Start from "./pages/Start";
import MemberList from "./pages/MemberList";
import AdminLoginPage from "./pages/AdminLogonPage";
import AdminTeam from "./pages/Admin";
import AdminPageHome from "./pages/AdminPageHome";
import Collection from "./pages/Collection";
function App() {
  // Defining pages and linking them with the react-router.
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [adminToken, setAdminToken] = useState(null);
  // Load token from local storage on component mount
  const [value, setValue] = useState(false);
  useEffect(() => {
    const savedToken = localStorage.getItem("user");
    const adminSavedToken = localStorage.getItem("token");

    if (savedToken) {
      setToken(savedToken);
    }
    if (adminSavedToken) {
      setAdminToken(adminSavedToken);
    }
  }, []);
  useEffect(() => {
    // Listen for the popstate event (browser back/forward button)
    window.onpopstate = () => {
      // //("sdjhdsjk");
      const redirectPath = sessionStorage.getItem("redirectPath");

      if (redirectPath) {
        // Clear the saved redirect path
        sessionStorage.removeItem("redirectPath");

        // Redirect to the saved path
        window.location.href = redirectPath;
      }
    };

    // Clean up the event listener on unmount
    return () => {
      window.onpopstate = null;
    };
  }, []);
  // useEffect(() => {
  //   // Check conditions and perform redirects
  //   if (token) {
  //     if (value === false) {
  //       navigate("/api/welcome/:id"); // Redirect to the Welcome page
  //     } else {
  //       window.location.replace("/yash"); // Redirect using JavaScript
  //     }
  //   }
  // }, [token, value]);

  return (
    <div className="App" id="App">
      <Routes>
        <Route path="" element={<Start />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset-password/:id" element={<Forgot />} />
        {/* <Route path="/welcome" element={<Welcome />} /> */}
        {/* <Route path="/apply" element={<UserApplicationPage />} /> */}
        {/*Have to be changed*/}
        <Route path="/admin" element={<AdminLoginPage />} />
        {adminToken && <Route path="/admin/stats" element={<AdminHome />} />}
        {adminToken && <Route path="/admin/home" element={<AdminPageHome />} />}
        {adminToken && <Route path="/admin/team" element={<MemberList />} />}
        {adminToken && <Route path="/admin/member" element={<AdminTeam />} />}
        {adminToken && (
          <Route path="/admin/collection" element={<Collection />} />
        )}
        <Route path="/verify">
          <Route path=":email">
            <Route path=":id" element={<VerifiedPage />} />
            <Route path="*" element={<ErrorPage />} />
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Route>
        <Route path="/api">
          {token && (
            <Route path="user_reg/:id" element={<UserApplicationPage />}>
              {/* {token && <Route path=":id" element={<UserApplicationPage />} />} */}
            </Route>
          )}
          {token && <Route path="/api/welcome/:id" element={<Welcome />} />}
          {/* {token && value === False && (
            <Route path="welcome/:id" element={<Welcome />} />
          )} */}
          {token && <Route path="create/:id" element={<Create />} />}
          {token && <Route path="join/:id" element={<Join />} />}

          {token && (
            <Route path="teamPage/:id/:user_id" element={<TeamComponent />} />
          )}

          {token && <Route path="idea/:id" element={<IdeaComponent />} />}
          <Route path="*" element={<ErrorPage />} />
        </Route>
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </div>
  );
}

export default App;
