import React, { useEffect } from "react";
import "ol/ol.css";
import "../assets/styles/maps.css"; // Import the CSS file for Maps component
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { fromLonLat } from "ol/proj";
import { Icon, Style, Text, Fill } from "ol/style";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";

const Maps = ({ countryTop }) => {
  useEffect(() => {
    const map = new Map({
      target: "map",
      layers: [
        new TileLayer({
          source: new XYZ({
            url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
          }),
        }),
      ],
      view: new View({
        center: fromLonLat([0, 0]),
        zoom: 2,
      }),
    });

    const markersSource = new VectorSource();
    const markersLayer = new VectorLayer({
      source: markersSource,
    });

    // Fetch coordinates for each country using Nominatim API
    countryTop?.forEach(async (country) => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=json&q=${country}`
        );
        const data = await response.json();
        if (data.length > 0) {
          const coordinates = fromLonLat([
            parseFloat(data[0].lon),
            parseFloat(data[0].lat),
          ]);
          const marker = new Feature({
            geometry: new Point(coordinates),
          });

          // Create a label for the country name
          const label = new Feature({
            geometry: new Point(coordinates),
            name: country, // Store the country name as a property
          });

          const markerStyle = new Style({
            image: new Icon({
              src: "https://cdn.mapmarker.io/api/v1/pin?icon=fa-circle&size=30&background=%23623ec9&color=%23FFFFFF",
            }),
          });
          marker.setStyle(markerStyle);
          markersSource.addFeature(marker);

          const labelStyle = new Style({
            text: new Text({
              text: country,
              font: "14px sans-serif",
              fill: new Fill({ color: "#000" }),
              offsetY: -20, // Offset label vertically
            }),
          });
          label.setStyle(labelStyle);
          markersSource.addFeature(label);
        }
      } catch (error) {
        console.error("Error fetching coordinates:", error);
      }
    });

    map.addLayer(markersLayer);

    // Adjust view to show markers at the end of the map
    const lastCountryCoordinates = markersSource
      .getFeatures()
      [countryTop.length - 1]?.getGeometry()
      .getCoordinates();
    if (lastCountryCoordinates) {
      map
        .getView()
        .fit(lastCountryCoordinates, { padding: [100, 100, 100, 100] });
    }

    return () => {
      map.dispose();
    };
  }, [countryTop]);

  return (
    <>
      <div id="map"></div>
    </>
  );
};

export default Maps;
