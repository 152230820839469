import React from "react";
import Statistics from "../components/TotalReg";
import NavbarUser from "../components/AdminNavbar";
import BottomBar from "../components/BottomBar";
import Sidebar from "../components/Sidebar";
import bg from "../assets/images/solid-color-image.png";
import { useEffect } from "react";
export default function AdminHome() {
  return (
    <div
      style={{
        background: "linear-gradient(135deg, #588380, #2f4443)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <NavbarUser />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Sidebar />
        <Statistics />
      </div>
      <BottomBar />
    </div>
  );
}
