import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";

import "../assets/styles/user_onboarding_flow.css";
import axios from "axios";
const LoginComponent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [user_name, setuser_name] = useState("");
  const [password, setPassword] = useState("");
  const handleChangeuser_name = (e) => {
    setuser_name(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [aa_user_name, setaa_user_name] = useState("");
  const [resetTitle, setResetTitle] = useState("RESET PASSWORD");
  const [modalHeight, setModalHeight] = useState("310px");
  const [timerEnd, setTimerEnd] = useState("none");
  const [resetMessage, setResetMessage] = useState(
    "Enter the user_name address for the account you wish to reset the password for",
  );
  const [resetFormVisible, setResetFormVisible] = useState(true);
  const [timer, setTimer] = useState(0);

  const openModal = () => {
    setModalIsOpen(true);
    setaa_user_name("");
    setResetTitle("RESET PASSWORD");
    setResetMessage(
      "Enter the user_name address for the account you wish to reset the password for",
    );
    setTimerEnd("none");

    setResetFormVisible(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setaa_user_name("");
    setModalHeight("310px");
    setTimer(0); // Reset timer when closing the modal
  };
  function formatTime(seconds) {
    if (seconds === parseInt(seconds, 10)) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(remainingSeconds).padStart(2, "0");

      return `${formattedMinutes}:${formattedSeconds}`;
    } else {
      return seconds;
    }
  }
  const handleChange = (e) => {
    const { value } = e.target;
    setaa_user_name(value);
  };

  const isValiduser_name = (user_name) => {
    const user_nameRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return user_nameRegex.test(user_name);
  };

  const senduser_name = () => {
    // // // //(aa_user_name);
    axios
      .post(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/reset-password-user_name`,
        {
          aa_user_name,
        },
      )
      .then((res) => {
        // Handle the message in the React component
        // // // //(res);
        // navigate(`${response?.data?.redirectUrl}`);
        // navigate( navigate(`${response?.data?.redirectUrl}`))
      })
      .catch((error) => {
        // // // //(error);
        // Handle error state
      });
    setResetTitle("CHECK user_name");
    setResetMessage(
      `A password reset link has been sent to <span style='color: red'>${aa_user_name}</span>.`,
    );
    setResetFormVisible(false);
    setModalHeight("190px");

    // Start the timer when the user_name is sent
    setTimer(30); // Set the initial value of the timer (60 seconds)
  };
  useEffect(() => {
    // Function to clear the error after 3 seconds
    const clearError = () => {
      setError("");
    };

    // If there is an error, set a timeout to clear it after 3 seconds
    if (error) {
      const timeout = setTimeout(clearError, 3000);
      return () => clearTimeout(timeout);
    }
  }, [error]);
  useEffect(() => {
    let interval;
    if (timer > 0) {
      setTimerEnd("none");

      // Start the interval to decrement the timer
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000); // 1000 milliseconds = 1 second
    } else if (timer === 0) {
      // // // //("Timer hit 0.");
      setTimerEnd("block");
    }

    // Cleanup the interval on component unmount
    return () => {
      clearInterval(interval);
    };
  }, [timer]);
  const handleSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();

    if (password.length < 8) {
      setError("Password must be at least 8 characters long");
      setTimeout(() => {
        setError(null);
      }, 3000); // Clear error message after 3 seconds
      setIsLoading(false);
      return;
    }

    const data = {
      username: user_name,
      password: password,
    };
    //(data);

    axios
      .post(
        // `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin-portal`,
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin-portal`,
        data,
      )
      .then((response) => {
        //(response);
        const user = response?.data?.token;
        //(response?.data?.token);
        localStorage.setItem("token", user);
        setuser_name("");
        setPassword("");
        window.location.replace("/admin/home");
      })
      .catch((error) => {
        //(error);
        setError(error?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false); // Disable loading state
      });
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  useEffect(() => {
    document.title = "Login HTM";
    return () => {
      document.title = "Portal | HTM";
    };
  }, []);
  // window.onbeforeunload = function () {
  //   localStorage.removeItem("user");
  //   return;
  // };
  const User = localStorage.getItem("user");
  return (
    <>
      <div className="onboard-box">
        <h1>LOGIN ADMIN</h1>

        <form onSubmit={handleSubmit}>
          <input
            required
            type="text"
            name="user_name"
            value={user_name}
            onChange={handleChangeuser_name}
            id="user_name-log"
            placeholder="Enter Your User Name"
            className="onboard-box-input"
          />
          <div className="password-input-container">
            <input
              required
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              value={password}
              onChange={handleChangePassword}
              placeholder="Enter Your Password"
              className="onboard-box-input2"
            />
            {password && (
              <div
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
              </div>
            )}
          </div>
          <button
            id="forgot"
            type="button"
            onClick={openModal}
            style={{ visibility: "hidden" }}
          >
            Forgot Password?
          </button>
          {error && (
            <h4 style={{ color: "red" }} className="error-in">
              {error}
            </h4>
          )}
          <button id="log">
            {isLoading ? (
              <span className="loading-circle" /> // Add a rotating circle (CSS animation)
            ) : (
              "LOGIN"
            )}
          </button>
        </form>

        <p style={{ visibility: "hidden" }}>
          Not Registered ? Click here to <Link to="/register">Register</Link>
        </p>
      </div>
    </>
  );
};

export default LoginComponent;
