import React from "react";
import Sidebar from "../components/Sidebar";
import NavbarUser from "../components/AdminNavbar";
import TeamDetails from "../components/TeamDetails";
import BottomBar from "../components/BottomBar";
import bg from "../assets/images/solid-color-image.png";
import { useEffect, useState } from "react";

export default function Admin() {
  return (
    <div
      className="wrapper"
      style={{
        background: "linear-gradient(135deg, #588380, #2f4443)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <NavbarUser />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Sidebar />
        <TeamDetails />
      </div>
      <BottomBar />
    </div>
  );
}
