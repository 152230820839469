import React from "react";
import NavbarUser from "../components/NavbarUser";
import Application from "../components/Application";
import bg from "../assets/images/background_1.png";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
export default function UserApplicationPage() {
  const parama = useParams();
  // //(parama.id);

  return (
    <div
      className="wrapper"
      style={{
        background: "linear-gradient(135deg, #588380, #2f4443)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      <NavbarUser />

      <Application data={parama.id} />
    </div>
  );
}
