import React, { useState, useEffect } from "react";
import "../assets/styles/navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
export default function AdminNavbar() {
  const [token, setToken] = useState();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/admin");
  };
  useEffect(() => {
    const Token = localStorage.getItem("name");
    setToken(Token);
  }, []);
  const trim = (name) => {
    // // //("hgjhgfhgfhjwgeyweghg");
    var Name = name?.split(" ");

    // // //(teamData?.team_leader?.name);
    return Name === undefined ? " " : Name[0];
  };
  //
  return (
    <>
      <div className="navbar-dynamic">
        <a href="https://hackthemountain.tech/" target="_blank">
          <img
            src={require("../assets/images/htm_logo.png")}
            alt=""
            className="nav-img"
          />
        </a>
        <div className="navLL">
          <div
            className="user-icon"
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
          >
            <FontAwesomeIcon className="i" icon={faUserCircle} />
            {isHovered && (
              <div className="user-info" style={{ zIndex: 9999 }}>
                <div onClick={handleLogout} className="logout">
                  Logout <i className="fa-solid fa-right-from-bracket"></i>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
