import React, { useState, useEffect, useRef } from "react";
import "../assets/styles/team.css";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import { ImCross } from "react-icons/im";
import axios from "axios";

import Countdown from "./Countdown";

const TeamInformation = (team) => {
  const [y, setY] = useState();
  const [rummer, setRummer] = useState("");
  // const [yash, setyash] = useState("");
  const [email, setEmail] = useState("");
  const [showLine, setShowLine] = useState(false);
  const [showLineLine, setShowLineLine] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalIsOpenOpen, setModalIsOpenOpen] = useState(false);
  const [resetTitle, setResetTitle] = useState("Delete Team");
  const [modalHeight, setModalHeight] = useState("310px");
  const [timerEnd, setTimerEnd] = useState("none");
  const [resetMessage, setResetMessage] = useState(
    "Enter the email address for the account you wish to reset the password for",
  );
  const [resetFormVisible, setResetFormVisible] = useState(true);
  const [timer, setTimer] = useState(0);

  // const [yash, setyash] = useState("");
  const [yash, setyash] = useState("");
  const [teamData, setTeamData] = useState();
  const params = useParams();
  // // // //(params);
  const [nn, setN] = useState();
  const [tteamId, setTeamId] = useState();
  const [iid, setId] = useState();
  const [teamteamname, setteamteamname] = useState();
  const handleButtonClick = (n, teamId, id, teamName) => {
    openModal();
    setN(n);
    setTeamId(teamId);
    setId(id);
    setteamteamname(teamName);

    if (yash === "") {
      return false;
    } else {
      if (yash === teamData?.teamname) {
        // return true;
        if (params?.user_id === id) {
          const jwtToken = localStorage.getItem("user");
          axios
            .post(
              "https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/api/teamPage/delete/" +
                teamId,
              { id },
              {
                headers: {
                  Authorization: `Bearer ${jwtToken}`,
                  "Content-Type": "application/json", // Assuming you're sending JSON data in the request body
                },
              },
            )
            .then((res) => {
              // Handle the message in the React component
              // // // //(res);
              //console.log(res);
              // window.location.href = `http://localhost:3000/api/welcome/${id}`;
              // window.location.href = `http://localhost:3000/api/welcome/${id}`;
            })
            .catch((error) => {
              // // // //(error);
              // Handle error state
            });
          return true;
        }
      }
    }
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLine(true);
    }, 5000);

    return () => clearTimeout(timeoutId);
  }, []);
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLineLine(true);
    }, 10000);

    return () => clearTimeout(timeoutId);
  }, []);
  // useEffect(() => {

  //   // // // // //("yash varshney");
  // }, []);
  const text = `To confirm, type <span style="color: red;">${teamData?.teamname}</span> in the box below`;
  const [sim, setSim] = useState(false);
  const openModal = () => {
    setSim(true);
    setModalIsOpen(true);
    // // // //("yash varshney");
    setyash("");
    setResetTitle("Delete Team");
    setResetMessage(text);
    setTimerEnd("none");

    setResetFormVisible(true);
  };
  const openModalModal = (n) => {
    setModalIsOpenOpen(true);
    // // // //("yash varshney");
    setyash("");
    setResetTitle("Remove from team");
    setResetMessage(
      `To confirm, type <span style="color: red;">${n}</span> in the box below`,
    );
    setTimerEnd("none");

    setResetFormVisible(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setyash("");
    setModalHeight("310px");
    setTimer(0);

    formatNavigate(rummer); // Reset timer when closing the modal
  };
  const closecloseModal = () => {
    setModalIsOpenOpen(false);
    setyash("");
    setModalHeight("310px");
    setTimer(0);
    formatNav(y, lll); // Reset timer when closing the modal
  };
  // const handleChangeEmail = (e) => {
  //   setEmail(e.target.value);
  // };
  const handleChange = (e) => {
    const { value } = e.target;
    setyash(value);
  };

  const sendEmail = () => {
    const value = handleButtonClick(nn, tteamId, iid, teamteamname);

    // // // //(rummer);
    // // // //(yash);
    setRummer(yash);
    // // // //("pppp");
    // // // //(typeof rummer);
    if (value) {
      setResetTitle("Delete Team");
      setResetMessage(
        `Team <span style="color: rgb(45, 144, 15);">${yash}</span> has been deleted successfully`,
      );
      setResetFormVisible(false);

      // <span style="color: red;">${teamData?.teamname}</span>
      setModalHeight("190px");

      setTimeout(() => {
        window.location.href = `https://portal.hackthemountain.tech/api/welcome/${iid}`;
      }, 5000);
    } else {
      setResetTitle("Team Delete");
      setResetMessage(`Team is not deleted.`);
      setResetFormVisible(false);
      setModalHeight("190px");
    }
    // Start the timer when the email is sent
    // Set the initial value of the timer (60 seconds)
  };
  const sendText = () => {
    const is = handleDelete(nnn, useruser, teamteamID, leaderIDID);

    setY(yash);
    // // // //(han);
    // // // //("oooooo");
    if (is) {
      setResetTitle("Member removed ");
      setResetMessage(
        `<span style="color: rgb(45, 144, 15);">${yash}</span> has been removed from the team successfully.`,
      );
      setResetFormVisible(false);
      setModalHeight("190px");

      setTimeout(() => {
        window.location.href = `https://portal.hackthemountain.tech/api/welcome/${params?.user_id}`;
      }, 5000);
      // Start the timer when the email is sent
      setTimer(5);
    } else {
      setResetTitle("Leave team ");
      setResetMessage(`You were not able to leave the team.`);
      setResetFormVisible(false);
      setModalHeight("190px");
      setTimer(5);
    }
  };

  const formatNavigate = (r) => {
    // // // //(yash);
    if (r === teamteamname)
      window.location.replace(`/api/welcome/${params?.user_id}`);
  };
  const formatNav = (y, id) => {
    if (id === leaderIDID) {
      window.location.replace(`/api/teamPage/${params?.id}/${params?.user_id}`);
    } else if (y === nnn) {
      window.location.replace(`/api/welcome/${params?.user_id}`);
    }
  };

  const url = `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/api/teamPage/${params?.id}/${params?.user_id}`;

  const [copyStatus, setCopyStatus] = useState(params?.id);

  const textRef = useRef(null);
  const FetchData = () => {
    setCopyStatus(teamData?.teamId);
  };
  let timeoutId = null;
  const handleCopyClick = () => {
    textRef.current.select();
    document.execCommand("copy");
    setCopyStatus("Copied!");
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(FetchData, 1000);
  };

  useEffect(() => {
    const jwtToken = localStorage.getItem("user");
    const fetchData = async () => {
      try {
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json", // Assuming you're sending JSON data in the request body
          },
        });
        setTeamData(response?.data?.team);
        if (response?.data?.ideaSubmission === undefined) {
          localStorage.setItem("value", "NOT-Submitted");
        } else {
          localStorage.setItem("value", "Submitted");
        }
        // setCard(response?.data?.team);
      } catch (error) {
        console.error("Error fetching data:", error);
        // localStorage.removeItem("user");
        // localStorage.removeItem("email");
        // localStorage.removeItem("name");
        // // //(error);
        // //(error);
        window.location.replace(`/api/welcome/${params?.user_id}`);
      }
    };

    fetchData();
    // const endDate = new Date();
    // endDate.setDate(endDate.getDate() + 1); // Set the end date to tomorrow
    // endDate.setHours(21, 30, 0); // Set the end time to 21:30

    // const updateCountdown = () => {
    //   const currentDate = new Date().getTime();
    //   const timeDifference = endDate - currentDate;

    //   if (timeDifference > 0) {
    //     const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    //     const hours = Math.floor(
    //       (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    //     );
    //     const minutes = Math.floor(
    //       (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    //     );
    //     const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    //     setRemainingTime(`${days}d:${hours}h:${minutes}m:${seconds}s`);
    //   } else {
    //     setRemainingTime("Timer Ended");
    //   }
    // };

    // const countdownInterval = setInterval(updateCountdown, 1000);

    // return () => {
    //   clearInterval(countdownInterval);
    // };
  }, []);

  // useEffect(() => {

  //   // // // // //("yash varshney");
  // }, []);
  const [nnn, setNN] = useState();
  const [useruser, setUserUser] = useState();
  const [teamteamID, setteamteamID] = useState();
  const [leaderIDID, setLeaderIDID] = useState();
  const [han, setHan] = useState(false);
  const [lll, setLLL] = useState();
  const handleDelete = (n, userId, teamI, leaderID) => {
    const teamId = params?.id;
    // // // //("lllll");
    openModalModal(n);
    setNN(n);
    setUserUser(userId);
    setteamteamID(teamId);
    setLeaderIDID(leaderID);
    // // // //(yash);
    if (yash === "") {
      return false;
    } else {
      if (yash === n || yash === teamData?.team_leader?.name) {
        const name = localStorage.getItem("name");

        if (name === n) {
          // // // //(name);
          // // // //(n);
          const jwtToken = localStorage.getItem("user");
          // // // //(params?.user_id);
          // // // //(userId);
          // // // //({ teamId, userId });
          axios
            .post(
              `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/api/teamPage/leave/` +
                params?.user_id,
              { teamId, userId },
              {
                headers: {
                  Authorization: `Bearer ${jwtToken}`,
                  "Content-Type": "application/json", // Assuming you're sending JSON data in the request body
                },
              },
            )
            .then((res) => {
              // Handle the message in the React component
              setHan(true);
              // // // //(han);
              // // // //("yyyyyy");
              // return true;
              // // // //(res);
              // window.location.replace(`/api/welcome/${params?.user_id}`);
            })
            .catch((error) => {
              // // // //("rrrrrrr");
              // // // //(error);
              // // // //("iii");
              setHan(false);
              return true;
              // Handle error state
            });
          return true;
        } else if (params?.user_id === leaderID) {
          // // // //("trtrtrtrtrtrt");
          setLLL(leaderID);
          const jwtToken = localStorage.getItem("user");
          axios
            .post(
              `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/api/teamPage/leave/` +
                params?.user_id,
              { teamId, userId },
              {
                headers: {
                  Authorization: `Bearer ${jwtToken}`,
                  "Content-Type": "application/json", // Assuming you're sending JSON data in the request body
                },
              },
            )
            .then((res) => {
              // Handle the message in the React component
              // // // //(res);
              // // // //("uyyuuy");
              // // // //("yash varshney njfvhjfvhfvhh");
              // window.location.replace(
              //   `/api/teamPage/${teamId}/${params?.user_id}`
              // );
            })
            .catch((error) => {
              // // // //(error);
              // // // //("u");
              return false;
              // Handle error state
            });
          return true;
        }
      } else {
        return false;
      }
    }
  };

  const trim = (name) => {
    // // // //("hgjhgfhgfhjwgeyweghg");
    var Name = name?.split(" ");

    // // // //(teamData?.team_leader?.name);
    return Name[0];
  };
  const isAdmin = teamData?.team_leader?.id === params?.user_id;
  const handleIdeaSubmit = () => {
    if (isAdmin) {
      const dataToSend = {
        // key1: teamData?.teamName,
        // key2: teamData?.team_leader?.id,
        key1: teamData?.teamname,
        key2: teamData?.team_leader?.name,
        key3: `/api/teamPage/${teamData?.teamId}/${params?.user_id}`,
      };
      const encodedData = btoa(JSON.stringify(dataToSend)); // Encode data as base64
      const targetUrl = `/api/idea/${params?.id}?data=${encodedData}`;
      window.location.replace(targetUrl);
      // // // //("Admin is submitting an idea");
    } else {
      // Logic for team member idea submission
      // // // //("Team member is submitting an idea");
    }
  };
  const isIdeaSubmitted = localStorage.getItem("value");

  const statusStyles = {
    textAlign: "center",
    backgroundColor: "#C4DADA",
    color: isIdeaSubmitted === "Submitted" ? "rgb(45, 144, 15)" : "black",
    fontWeight: "bold",
    letterSpacing: "1px",
    textDecoration: "none",
    borderRadius: "10px",
    marginBottom: "-20px",
    fontSize: "18px",
    paddingTop: "10px",
  };
  function navigateToPage() {
    const desiredPageUrl = "/login"; // Replace with the URL of the page you want to navigate to
    localStorage.removeItem("user");
    localStorage.removeItem("email");
    localStorage.removeItem("name");
    localStorage.removeItem("value");
    window.location.href = desiredPageUrl;
  }

  // Add an event listener for the popstate event
  window.addEventListener("popstate", (event) => {
    // Execute the function to navigate the user to the desired page
    navigateToPage();
  });

  // Push a new state to the history when the user lands on the current page
  // This will ensure that there's always a state in the history stack for this page
  window.history.pushState({ page: "current" }, null, window.location.href);
  window.history.pushState({ page: "current" }, null, window.location.href);
  useEffect(() => {
    document.title = "Team Information";
    const favicon = document.getElementById("favicon");
    favicon.href =
      "https://htm-4-images.s3.amazonaws.com/img/HTMLOGHO_%20NEW.png";
    return () => {
      document.title = "Portal | HTM";
    };
  }, []);
  // window.onbeforeunload = function () {
  //   localStorage.removeItem("user");
  //   localStorage.removeItem("name");
  //   return;
  // };
  const [ideaSubmissionPhase, setIdeaSubmissionPhase] = useState(false);
  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(`https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/stats`)
      .then((res) => {
        // // conso("yash varshney");
        // conso(res);

        setIdeaSubmissionPhase(res?.data[0]?.ideaSubmission);
        // // conso(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);
  return (
    <>
      {/* 5a4c6a/3e8d635b-db3f-48cc-8473-eee87b5f0b21 */}

      <div className="final-box">
        <div className="main">
          <h1 className="heading">
            TEAM INFORMATION{" "}
            {teamData?.online === true ? (
              <span>(Online)</span>
            ) : teamData?.online === false ? (
              <span>(Offline)</span>
            ) : null}
          </h1>
          <div className="container">
            <div className="sec-1">
              <div className="team-info">
                <div className="team-riders-container">
                  <h2 className="team-riders">{teamData?.teamname}</h2>
                  {/* // // // //(params?.user_id) */}
                  {teamData?.team_leader?.id === params?.user_id &&
                    isIdeaSubmitted === "NOT-Submitted" && (
                      <span
                        className="delete-button"
                        style={{ marginTop: "10px" }}
                        onClick={() =>
                          handleButtonClick(
                            teamData?.team_leader?.name,
                            teamData?.teamId,
                            teamData?.team_leader?.id,
                            teamData?.teamname,
                          )
                        }
                      >
                        Delete Team
                      </span>
                    )}
                </div>

                <div className="description-team-code">
                  <p className="description">
                    {teamData?.online === true ? (
                      <span>
                        Maximum 4 members are allowed. Share the invite code
                        below to add members.
                      </span>
                    ) : teamData?.online === false ? (
                      <span>
                        To participate in offline mode, your team must have 4
                        members. You can submit your idea now, but your team
                        will only be evaluated once it has 4 members. Share the
                        invite code below to add members.
                      </span>
                    ) : null}
                  </p>
                  <div className="team-code">
                    <p className="code">INVITE CODE</p>
                    <div className="copy">
                      <input
                        type="text"
                        value={copyStatus}
                        ref={textRef}
                        readOnly
                        style={{
                          background: "#e6e6e6",
                          border: "transparent",
                          borderRadius: "50px",
                        }}
                      />
                      <button className="copy-button" onClick={handleCopyClick}>
                        <i className="fa fa-clone"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="teams">
                <div className="temm">
                  <h3 className="team-riders-container-2">Team Members</h3>
                </div>
                <div className="team-members-container">
                  <div className="team-member">
                    <span className="team-member-name">
                      {teamData?.team_leader?.name === undefined
                        ? " "
                        : trim(teamData?.team_leader?.name)}
                    </span>
                    <span className="team-admin">(Leader)</span>
                  </div>

                  {/* {teamData?.members?.map((e) => {
                  return (
                    <div className="team-member">
                      <span className="team-member-name">{e.name}</span>
                      <span className="delete-button">&#10006;</span>
                    </div>
                  );
                })} */}
                  {teamData?.members[0]?.name && (
                    <div className="team-member">
                      <span className="team-member-name">
                        {teamData?.members[0]?.name === undefined
                          ? " "
                          : trim(teamData?.members[0]?.name)}
                      </span>
                      {teamData?.members[0]?.id === params?.user_id && (
                        <span
                          className="delete-button"
                          onClick={() =>
                            handleDelete(
                              teamData?.members[0]?.name,
                              teamData?.members[0]?.id,
                              teamData?.teamId,
                              teamData?.team_leader?.id,
                            )
                          }
                          // onChange={setSecond(true)}
                        >
                          &#10006;
                        </span>
                      )}
                      {teamData?.team_leader?.id === params?.user_id && (
                        <span
                          className="delete-button"
                          onClick={() =>
                            handleDelete(
                              teamData?.members[0]?.name,
                              teamData?.members[0]?.id,
                              teamData?.teamId,
                              teamData?.team_leader?.id,
                            )
                          }
                        >
                          &#10006;
                        </span>
                      )}
                    </div>
                  )}
                  {/* // // // //(teamData?.teamId)} */}
                  {teamData?.members[1]?.name && (
                    <div className="team-member">
                      <span className="team-member-name">
                        {teamData?.members[1]?.name === undefined
                          ? " "
                          : trim(teamData?.members[1]?.name)}
                      </span>
                      {teamData?.members[1]?.id === params?.user_id && (
                        <span
                          className="delete-button"
                          onClick={() =>
                            handleDelete(
                              teamData?.members[1]?.name,
                              teamData?.members[1]?.id,
                              teamData?.teamId,
                              teamData?.team_leader?.id,
                            )
                          }
                          // onChange={setSecond(true)}
                        >
                          &#10006;
                        </span>
                      )}
                      {teamData?.team_leader?.id === params?.user_id && (
                        <span
                          className="delete-button"
                          onClick={() =>
                            handleDelete(
                              teamData?.members[1]?.name,
                              teamData?.members[1]?.id,
                              teamData?.teamId,
                              teamData?.team_leader?.id,
                            )
                          }
                        >
                          &#10006;
                        </span>
                      )}
                    </div>
                  )}
                  {teamData?.members[2]?.name && (
                    <div className="team-member">
                      <span className="team-member-name">
                        {teamData?.members[2]?.name === undefined
                          ? " "
                          : trim(teamData?.members[2]?.name)}
                      </span>
                      {teamData?.members[2]?.id === params?.user_id && (
                        <span
                          className="delete-button"
                          onClick={() =>
                            handleDelete(
                              teamData?.members[2]?.name,
                              teamData?.members[2]?.id,
                              teamData?.teamId,
                              teamData?.team_leader?.id,
                            )
                          }
                          // onChange={setSecond(true)}
                        >
                          &#10006;
                        </span>
                      )}
                      {teamData?.team_leader?.id === params?.user_id && (
                        <span
                          className="delete-button"
                          onClick={() =>
                            handleDelete(
                              teamData?.members[2]?.name,
                              teamData?.members[2]?.id,
                              teamData?.teamId,
                              teamData?.team_leader?.id,
                            )
                          }
                        >
                          &#10006;
                        </span>
                      )}
                    </div>
                  )}
                  {/* Add more team members */}
                </div>
              </div>
              <hr className="hr" />
            </div>

            {teamData?.online === false ? (
              <div className="sec-2">
                {/* <h4>REMAINING TIME LEFT TO SUBMIT</h4> */}
                <div className="idea-sub">
                  {/* <p className="h22">Idea Submission is starting soon.......</p> */}
                </div>
                <h3>Evaluation Form</h3>
                <div className="countdown">
                  <div className="border">
                    <div className="countdown-text">
                      {ideaSubmissionPhase ? (
                        <Countdown />
                      ) : (
                        <p className="h22">STARTS SOON</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="idea">
                  <p className="status" style={statusStyles}>
                    STATUS :{" "}
                    {isIdeaSubmitted === "Submitted"
                      ? "Evaluation form submitted"
                      : "Evaluation form not submitted"}
                  </p>
                  <br />

                  {ideaSubmissionPhase && teamData?.member_count > 1 ? (
                    isAdmin ? (
                      <button
                        className="point"
                        style={{ backgroundColor: "#588380" }}
                        onClick={handleIdeaSubmit}
                      >
                        {isIdeaSubmitted === "Submitted"
                          ? "Edit Evaluation Form"
                          : "Submit Evaluation Form"}
                      </button>
                    ) : (
                      <button className="demi-point" disabled>
                        SUBMIT YOUR IDEA
                      </button>
                    )
                  ) : (
                    <button className="demi-point" disabled>
                      SUBMIT YOUR IDEA
                    </button>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Delete Modal"
        style={{ content: { borderRadius: "20px", height: modalHeight } }}
      >
        <div className="modal-top">
          <h1 id="reset-title">{resetTitle}</h1>
          <button id="reset-button" onClick={closeModal}>
            <ImCross />
          </button>
        </div>
        <p id="reset-p" dangerouslySetInnerHTML={{ __html: resetMessage }} />
        {resetFormVisible && (
          <form id="reset-form">
            <input
              onChange={handleChange}
              value={aa_email}
              className="email-input"
              type="email"
              name="email"
              id="email-res"
              autoComplete="off"
              placeholder="Enter Your Email"
            />
            <br />
            <button
              className="submit-button"
              type="button"
              onClick={sendEmail}
              disabled={!isValidEmail(aa_email)}
            >
              SUBMIT
            </button>
          </form>
        )}
        {timer > 0 && <div className="timer">{formatTime(timer)}</div>}
        <div className="timer" style={{ display: timerEnd }}>
          <a className="resend">Resend</a>
        </div>
      </Modal> */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Delete Team Modal"
          style={{ content: { borderRadius: "20px", height: modalHeight } }}
        >
          <div className="modal-top">
            <h1 id="reset-title">{resetTitle}</h1>
            <button id="reset-button" onClick={closeModal}>
              <ImCross />
            </button>
          </div>
          <p id="reset-p" dangerouslySetInnerHTML={{ __html: resetMessage }} />
          {resetFormVisible && (
            <form id="reset-form">
              <input
                onChange={handleChange}
                value={yash}
                className="email-input"
                type="text"
                name="text"
                id="email-res"
                autoComplete="off"
                placeholder=""
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault(); // Prevent the default form submission behavior
                    sendEmail();
                  }
                }}
              />
              <br />
              <button
                className="submit-button"
                type="button"
                onClick={sendEmail}

                // disabled={!isValidEmail(yash)}
              >
                SUBMIT
              </button>
            </form>
          )}
          {/* {formatNavigate()} */}
          {/* {rummer && showLineLine && (
          <div className="timer">{sim && formatNavigate()}</div>
        )} */}
          {/* <div className="timer" style={{ display: timerEnd }}></div> */}
        </Modal>
        <Modal
          isOpen={modalIsOpenOpen}
          onRequestClose={closecloseModal}
          contentLabel="Leave Team Modal"
          style={{ content: { borderRadius: "20px", height: modalHeight } }}
        >
          <div className="modal-top">
            <h1 id="reset-title">{resetTitle}</h1>
            <button id="reset-button" onClick={closecloseModal}>
              <ImCross />
            </button>
          </div>
          <p id="reset-p" dangerouslySetInnerHTML={{ __html: resetMessage }} />
          {resetFormVisible && (
            <form id="reset-form">
              <input
                onChange={handleChange}
                value={yash}
                className="email-input"
                type="text"
                name="text"
                id="email-res"
                autoComplete="off"
                placeholder=""
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault(); // Prevent the default form submission behavior
                    sendText();
                  }
                }}
              />
              <br />
              <button
                className="submit-button"
                type="button"
                onClick={sendText}
                // disabled={!isValidEmail(yash)}
              >
                SUBMIT
              </button>
            </form>
          )}
          {/* {formatNavigate()} */}
          {/* {ram && <div className="timer">{showLineLine && formatNav()}</div>} */}
          {/* <div className="timer" style={{ display: timerEnd }}></div> */}
        </Modal>
      </div>
    </>
  );
};

export default TeamInformation;
