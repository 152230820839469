import React, { useState, useEffect } from "react";
import axios from "axios";
const MemberDetails = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const membersPerPage = 7;
  const [data, setData] = useState();
  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/users`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        // //("yash varshney");
        //(res?.data);
        // console.log(res?.data);
        setData(res?.data);
        // //(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);
  const memberData = [
    {
      fullName: "John Doe",
      contact: "+1234567890",
      gender: "Male",
      age: 25,
      organization: "ABC Company",
      course: "Computer Science",
      address: "123 Main St",
      country: "United States",
      state: "California",
      pincode: "12345",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/johndoe",
      githubURL: "https://www.github.com/johndoe",
      twitterURL: "https://www.twitter.com/johndoe",
    },
    {
      fullName: "Jane Smith",
      contact: "+9876543210",
      gender: "Female",
      age: 30,
      organization: "XYZ Corporation",
      course: "Business Administration",
      address: "456 Park Ave",
      country: "United States",
      state: "New York",
      pincode: "67890",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/janesmith",
      githubURL: "https://www.github.com/janesmith",
      twitterURL: "https://www.twitter.com/janesmith",
    },
    // Add more member entries here
    {
      fullName: "Michael Johnson",
      contact: "+1122334455",
      gender: "Male",
      age: 28,
      organization: "DEF Enterprises",
      course: "Marketing",
      address: "789 Broadway",
      country: "United States",
      state: "Texas",
      pincode: "54321",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "L",
      linkedinURL: "https://www.linkedin.com/michaeljohnson",
      githubURL: "https://www.github.com/michaeljohnson",
      twitterURL: "https://www.twitter.com/michaeljohnson",
    },
    {
      fullName: "Emily Davis",
      contact: "+9988776655",
      gender: "Female",
      age: 32,
      organization: "GHI Group",
      course: "Finance",
      address: "987 Elm St",
      country: "United States",
      state: "Florida",
      pincode: "23456",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "XL",
      linkedinURL: "https://www.linkedin.com/emilydavis",
      githubURL: "https://www.github.com/emilydavis",
      twitterURL: "https://www.twitter.com/emilydavis",
    },
    {
      fullName: "David Wilson",
      contact: "+1122334455",
      gender: "Male",
      age: 29,
      organization: "PQR Co.",
      course: "Engineering",
      address: "321 Oak Ave",
      country: "United States",
      state: "Washington",
      pincode: "87654",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/davidwilson",
      githubURL: "https://www.github.com/davidwilson",
      twitterURL: "https://www.twitter.com/davidwilson",
    },
    {
      fullName: "Sarah Thompson",
      contact: "+9988776655",
      gender: "Female",
      age: 27,
      organization: "LMN Solutions",
      course: "Design",
      address: "654 Pine St",
      country: "United States",
      state: "Colorado",
      pincode: "34567",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/sarahthompson",
      githubURL: "https://www.github.com/sarahthompson",
      twitterURL: "https://www.twitter.com/sarahthompson",
    },
    {
      fullName: "John Doe",
      contact: "+1234567890",
      gender: "Male",
      age: 25,
      organization: "ABC Company",
      course: "Computer Science",
      address: "123 Main St",
      country: "United States",
      state: "California",
      pincode: "12345",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/johndoe",
      githubURL: "https://www.github.com/johndoe",
      twitterURL: "https://www.twitter.com/johndoe",
    },
    {
      fullName: "Jane Smith",
      contact: "+9876543210",
      gender: "Female",
      age: 30,
      organization: "XYZ Corporation",
      course: "Business Administration",
      address: "456 Park Ave",
      country: "United States",
      state: "New York",
      pincode: "67890",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/janesmith",
      githubURL: "https://www.github.com/janesmith",
      twitterURL: "https://www.twitter.com/janesmith",
    },
    // Add more member entries here
    {
      fullName: "Michael Johnson",
      contact: "+1122334455",
      gender: "Male",
      age: 28,
      organization: "DEF Enterprises",
      course: "Marketing",
      address: "789 Broadway",
      country: "United States",
      state: "Texas",
      pincode: "54321",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "L",
      linkedinURL: "https://www.linkedin.com/michaeljohnson",
      githubURL: "https://www.github.com/michaeljohnson",
      twitterURL: "https://www.twitter.com/michaeljohnson",
    },
    {
      fullName: "Emily Davis",
      contact: "+9988776655",
      gender: "Female",
      age: 32,
      organization: "GHI Group",
      course: "Finance",
      address: "987 Elm St",
      country: "United States",
      state: "Florida",
      pincode: "23456",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "XL",
      linkedinURL: "https://www.linkedin.com/emilydavis",
      githubURL: "https://www.github.com/emilydavis",
      twitterURL: "https://www.twitter.com/emilydavis",
    },
    {
      fullName: "David Wilson",
      contact: "+1122334455",
      gender: "Male",
      age: 29,
      organization: "PQR Co.",
      course: "Engineering",
      address: "321 Oak Ave",
      country: "United States",
      state: "Washington",
      pincode: "87654",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/davidwilson",
      githubURL: "https://www.github.com/davidwilson",
      twitterURL: "https://www.twitter.com/davidwilson",
    },
    {
      fullName: "Sarah Thompson",
      contact: "+9988776655",
      gender: "Female",
      age: 27,
      organization: "LMN Solutions",
      course: "Design",
      address: "654 Pine St",
      country: "United States",
      state: "Colorado",
      pincode: "34567",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/sarahthompson",
      githubURL: "https://www.github.com/sarahthompson",
      twitterURL: "https://www.twitter.com/sarahthompson",
    },
    {
      fullName: "John Doe",
      contact: "+1234567890",
      gender: "Male",
      age: 25,
      organization: "ABC Company",
      course: "Computer Science",
      address: "123 Main St",
      country: "United States",
      state: "California",
      pincode: "12345",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/johndoe",
      githubURL: "https://www.github.com/johndoe",
      twitterURL: "https://www.twitter.com/johndoe",
    },
    {
      fullName: "Jane Smith",
      contact: "+9876543210",
      gender: "Female",
      age: 30,
      organization: "XYZ Corporation",
      course: "Business Administration",
      address: "456 Park Ave",
      country: "United States",
      state: "New York",
      pincode: "67890",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/janesmith",
      githubURL: "https://www.github.com/janesmith",
      twitterURL: "https://www.twitter.com/janesmith",
    },
    // Add more member entries here
    {
      fullName: "Michael Johnson",
      contact: "+1122334455",
      gender: "Male",
      age: 28,
      organization: "DEF Enterprises",
      course: "Marketing",
      address: "789 Broadway",
      country: "United States",
      state: "Texas",
      pincode: "54321",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "L",
      linkedinURL: "https://www.linkedin.com/michaeljohnson",
      githubURL: "https://www.github.com/michaeljohnson",
      twitterURL: "https://www.twitter.com/michaeljohnson",
    },
    {
      fullName: "Emily Davis",
      contact: "+9988776655",
      gender: "Female",
      age: 32,
      organization: "GHI Group",
      course: "Finance",
      address: "987 Elm St",
      country: "United States",
      state: "Florida",
      pincode: "23456",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "XL",
      linkedinURL: "https://www.linkedin.com/emilydavis",
      githubURL: "https://www.github.com/emilydavis",
      twitterURL: "https://www.twitter.com/emilydavis",
    },
    {
      fullName: "David Wilson",
      contact: "+1122334455",
      gender: "Male",
      age: 29,
      organization: "PQR Co.",
      course: "Engineering",
      address: "321 Oak Ave",
      country: "United States",
      state: "Washington",
      pincode: "87654",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/davidwilson",
      githubURL: "https://www.github.com/davidwilson",
      twitterURL: "https://www.twitter.com/davidwilson",
    },
    {
      fullName: "Sarah Thompson",
      contact: "+9988776655",
      gender: "Female",
      age: 27,
      organization: "LMN Solutions",
      course: "Design",
      address: "654 Pine St",
      country: "United States",
      state: "Colorado",
      pincode: "34567",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/sarahthompson",
      githubURL: "https://www.github.com/sarahthompson",
      twitterURL: "https://www.twitter.com/sarahthompson",
    },
    {
      fullName: "John Doe",
      contact: "+1234567890",
      gender: "Male",
      age: 25,
      organization: "ABC Company",
      course: "Computer Science",
      address: "123 Main St",
      country: "United States",
      state: "California",
      pincode: "12345",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/johndoe",
      githubURL: "https://www.github.com/johndoe",
      twitterURL: "https://www.twitter.com/johndoe",
    },
    {
      fullName: "Jane Smith",
      contact: "+9876543210",
      gender: "Female",
      age: 30,
      organization: "XYZ Corporation",
      course: "Business Administration",
      address: "456 Park Ave",
      country: "United States",
      state: "New York",
      pincode: "67890",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/janesmith",
      githubURL: "https://www.github.com/janesmith",
      twitterURL: "https://www.twitter.com/janesmith",
    },
    // Add more member entries here
    {
      fullName: "Michael Johnson",
      contact: "+1122334455",
      gender: "Male",
      age: 28,
      organization: "DEF Enterprises",
      course: "Marketing",
      address: "789 Broadway",
      country: "United States",
      state: "Texas",
      pincode: "54321",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "L",
      linkedinURL: "https://www.linkedin.com/michaeljohnson",
      githubURL: "https://www.github.com/michaeljohnson",
      twitterURL: "https://www.twitter.com/michaeljohnson",
    },
    {
      fullName: "Emily Davis",
      contact: "+9988776655",
      gender: "Female",
      age: 32,
      organization: "GHI Group",
      course: "Finance",
      address: "987 Elm St",
      country: "United States",
      state: "Florida",
      pincode: "23456",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "XL",
      linkedinURL: "https://www.linkedin.com/emilydavis",
      githubURL: "https://www.github.com/emilydavis",
      twitterURL: "https://www.twitter.com/emilydavis",
    },
    {
      fullName: "David Wilson",
      contact: "+1122334455",
      gender: "Male",
      age: 29,
      organization: "PQR Co.",
      course: "Engineering",
      address: "321 Oak Ave",
      country: "United States",
      state: "Washington",
      pincode: "87654",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/davidwilson",
      githubURL: "https://www.github.com/davidwilson",
      twitterURL: "https://www.twitter.com/davidwilson",
    },
    {
      fullName: "Sarah Thompson",
      contact: "+9988776655",
      gender: "Female",
      age: 27,
      organization: "LMN Solutions",
      course: "Design",
      address: "654 Pine St",
      country: "United States",
      state: "Colorado",
      pincode: "34567",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/sarahthompson",
      githubURL: "https://www.github.com/sarahthompson",
      twitterURL: "https://www.twitter.com/sarahthompson",
    },
    {
      fullName: "John Doe",
      contact: "+1234567890",
      gender: "Male",
      age: 25,
      organization: "ABC Company",
      course: "Computer Science",
      address: "123 Main St",
      country: "United States",
      state: "California",
      pincode: "12345",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/johndoe",
      githubURL: "https://www.github.com/johndoe",
      twitterURL: "https://www.twitter.com/johndoe",
    },
    {
      fullName: "Jane Smith",
      contact: "+9876543210",
      gender: "Female",
      age: 30,
      organization: "XYZ Corporation",
      course: "Business Administration",
      address: "456 Park Ave",
      country: "United States",
      state: "New York",
      pincode: "67890",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/janesmith",
      githubURL: "https://www.github.com/janesmith",
      twitterURL: "https://www.twitter.com/janesmith",
    },
    // Add more member entries here
    {
      fullName: "Michael Johnson",
      contact: "+1122334455",
      gender: "Male",
      age: 28,
      organization: "DEF Enterprises",
      course: "Marketing",
      address: "789 Broadway",
      country: "United States",
      state: "Texas",
      pincode: "54321",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "L",
      linkedinURL: "https://www.linkedin.com/michaeljohnson",
      githubURL: "https://www.github.com/michaeljohnson",
      twitterURL: "https://www.twitter.com/michaeljohnson",
    },
    {
      fullName: "Emily Davis",
      contact: "+9988776655",
      gender: "Female",
      age: 32,
      organization: "GHI Group",
      course: "Finance",
      address: "987 Elm St",
      country: "United States",
      state: "Florida",
      pincode: "23456",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "XL",
      linkedinURL: "https://www.linkedin.com/emilydavis",
      githubURL: "https://www.github.com/emilydavis",
      twitterURL: "https://www.twitter.com/emilydavis",
    },
    {
      fullName: "David Wilson",
      contact: "+1122334455",
      gender: "Male",
      age: 29,
      organization: "PQR Co.",
      course: "Engineering",
      address: "321 Oak Ave",
      country: "United States",
      state: "Washington",
      pincode: "87654",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/davidwilson",
      githubURL: "https://www.github.com/davidwilson",
      twitterURL: "https://www.twitter.com/davidwilson",
    },
    {
      fullName: "Sarah Thompson",
      contact: "+9988776655",
      gender: "Female",
      age: 27,
      organization: "LMN Solutions",
      course: "Design",
      address: "654 Pine St",
      country: "United States",
      state: "Colorado",
      pincode: "34567",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/sarahthompson",
      githubURL: "https://www.github.com/sarahthompson",
      twitterURL: "https://www.twitter.com/sarahthompson",
    },
    {
      fullName: "John Doe",
      contact: "+1234567890",
      gender: "Male",
      age: 25,
      organization: "ABC Company",
      course: "Computer Science",
      address: "123 Main St",
      country: "United States",
      state: "California",
      pincode: "12345",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/johndoe",
      githubURL: "https://www.github.com/johndoe",
      twitterURL: "https://www.twitter.com/johndoe",
    },
    {
      fullName: "Jane Smith",
      contact: "+9876543210",
      gender: "Female",
      age: 30,
      organization: "XYZ Corporation",
      course: "Business Administration",
      address: "456 Park Ave",
      country: "United States",
      state: "New York",
      pincode: "67890",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/janesmith",
      githubURL: "https://www.github.com/janesmith",
      twitterURL: "https://www.twitter.com/janesmith",
    },
    // Add more member entries here
    {
      fullName: "Michael Johnson",
      contact: "+1122334455",
      gender: "Male",
      age: 28,
      organization: "DEF Enterprises",
      course: "Marketing",
      address: "789 Broadway",
      country: "United States",
      state: "Texas",
      pincode: "54321",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "L",
      linkedinURL: "https://www.linkedin.com/michaeljohnson",
      githubURL: "https://www.github.com/michaeljohnson",
      twitterURL: "https://www.twitter.com/michaeljohnson",
    },
    {
      fullName: "Emily Davis",
      contact: "+9988776655",
      gender: "Female",
      age: 32,
      organization: "GHI Group",
      course: "Finance",
      address: "987 Elm St",
      country: "United States",
      state: "Florida",
      pincode: "23456",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "XL",
      linkedinURL: "https://www.linkedin.com/emilydavis",
      githubURL: "https://www.github.com/emilydavis",
      twitterURL: "https://www.twitter.com/emilydavis",
    },
    {
      fullName: "David Wilson",
      contact: "+1122334455",
      gender: "Male",
      age: 29,
      organization: "PQR Co.",
      course: "Engineering",
      address: "321 Oak Ave",
      country: "United States",
      state: "Washington",
      pincode: "87654",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/davidwilson",
      githubURL: "https://www.github.com/davidwilson",
      twitterURL: "https://www.twitter.com/davidwilson",
    },
    {
      fullName: "Sarah Thompson",
      contact: "+9988776655",
      gender: "Female",
      age: 27,
      organization: "LMN Solutions",
      course: "Design",
      address: "654 Pine St",
      country: "United States",
      state: "Colorado",
      pincode: "34567",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/sarahthompson",
      githubURL: "https://www.github.com/sarahthompson",
      twitterURL: "https://www.twitter.com/sarahthompson",
    },
    {
      fullName: "John Doe",
      contact: "+1234567890",
      gender: "Male",
      age: 25,
      organization: "ABC Company",
      course: "Computer Science",
      address: "123 Main St",
      country: "United States",
      state: "California",
      pincode: "12345",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/johndoe",
      githubURL: "https://www.github.com/johndoe",
      twitterURL: "https://www.twitter.com/johndoe",
    },
    {
      fullName: "Jane Smith",
      contact: "+9876543210",
      gender: "Female",
      age: 30,
      organization: "XYZ Corporation",
      course: "Business Administration",
      address: "456 Park Ave",
      country: "United States",
      state: "New York",
      pincode: "67890",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/janesmith",
      githubURL: "https://www.github.com/janesmith",
      twitterURL: "https://www.twitter.com/janesmith",
    },
    // Add more member entries here
    {
      fullName: "Michael Johnson",
      contact: "+1122334455",
      gender: "Male",
      age: 28,
      organization: "DEF Enterprises",
      course: "Marketing",
      address: "789 Broadway",
      country: "United States",
      state: "Texas",
      pincode: "54321",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "L",
      linkedinURL: "https://www.linkedin.com/michaeljohnson",
      githubURL: "https://www.github.com/michaeljohnson",
      twitterURL: "https://www.twitter.com/michaeljohnson",
    },
    {
      fullName: "Emily Davis",
      contact: "+9988776655",
      gender: "Female",
      age: 32,
      organization: "GHI Group",
      course: "Finance",
      address: "987 Elm St",
      country: "United States",
      state: "Florida",
      pincode: "23456",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "XL",
      linkedinURL: "https://www.linkedin.com/emilydavis",
      githubURL: "https://www.github.com/emilydavis",
      twitterURL: "https://www.twitter.com/emilydavis",
    },
    {
      fullName: "David Wilson",
      contact: "+1122334455",
      gender: "Male",
      age: 29,
      organization: "PQR Co.",
      course: "Engineering",
      address: "321 Oak Ave",
      country: "United States",
      state: "Washington",
      pincode: "87654",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "M",
      linkedinURL: "https://www.linkedin.com/davidwilson",
      githubURL: "https://www.github.com/davidwilson",
      twitterURL: "https://www.twitter.com/davidwilson",
    },
    {
      fullName: "Sarah Thompson",
      contact: "+9988776655",
      gender: "Female",
      age: 27,
      organization: "LMN Solutions",
      course: "Design",
      address: "654 Pine St",
      country: "United States",
      state: "Colorado",
      pincode: "34567",
      bio: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      tshirtSize: "S",
      linkedinURL: "https://www.linkedin.com/sarahthompson",
      githubURL: "https://www.github.com/sarahthompson",
      twitterURL: "https://www.twitter.com/sarahthompson",
    },
  ];

  const indexOfLastMember = currentPage * membersPerPage;
  const indexOfFirstMember = indexOfLastMember - membersPerPage;
  const currentMembers = data?.slice(indexOfFirstMember, indexOfLastMember);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  return (
    <div>
      <div className="member-details">
        <table className="member-table">
          <thead>
            <tr>
              <th>Member</th>
              <th>Contact No.</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Course & Year</th>
              <th>Organization</th>
              <th>Address</th>
              <th>Country</th>
              <th>State</th>
              <th>Pincode</th>
              <th>Shirt Size</th>
              <th>Bio</th>
              <th>Linkedin</th>
              <th>Twitter</th>
              <th>Github</th>
            </tr>
          </thead>
          <tbody>
            {currentMembers?.map(
              (data) => (
                <tr style={{ border: "1px solid" }}>
                  <td>{data.ac_name}</td>
                  <td style={{ border: "1px solid" }}>{data.c_phone}</td>
                  <td style={{ border: "1px solid" }}>{data.e_gender}</td>
                  <td style={{ border: "1px solid" }}>{data.d_age}</td>
                  <td style={{ border: "1px solid" }}>{data.h_course_year}</td>
                  <td style={{ border: "1px solid" }}>{data.i_organization}</td>
                  <td style={{ border: "1px solid" }}>{data.j_address}</td>
                  <td style={{ border: "1px solid" }}>{data.l_country}</td>
                  <td style={{ border: "1px solid" }}>{data.k_res_state}</td>
                  <td style={{ border: "1px solid" }}>{data.m_pincode}</td>
                  <td style={{ border: "1px solid" }}>{data.f_tShirtSize}</td>
                  <td style={{ border: "1px solid" }}>{data.n_bio}</td>
                  <td style={{ border: "1px solid" }}>
                    <a
                      href={data.p_linkedin}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      Linkedin
                    </a>
                  </td>
                  <td style={{ border: "1px solid" }}>
                    {" "}
                    <a
                      href={data.q_twitter}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      Twitter
                    </a>
                  </td>
                  <td style={{ border: "1px solid" }}>
                    {" "}
                    <a
                      href={data.o_github}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      Github
                    </a>
                  </td>
                </tr>
              ),
              // //("done")
            )}
          </tbody>
        </table>
      </div>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button
          onClick={handlePreviousPage}
          className="previous-btn"
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <button
          className="next-btn"
          onClick={handleNextPage}
          disabled={indexOfLastMember >= data?.length}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default MemberDetails;
