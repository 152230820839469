import React, { useState, useEffect } from "react";
import "../assets/styles/team-details.css";
import axios from "axios";
import Papa from "papaparse";

const entriesPerPage = 10; // Number of entries to display per page

export default function CollectionData() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [startIndex, setStartIndex] = useState(0);
  const [showIncompleteData, setShowIncompleteData] = useState(false);

  // ...

  // Calculate the index range for the current page
  const endIndex = startIndex + entriesPerPage;
  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setStartIndex(startIndex - entriesPerPage);
    }
  };

  const handleNextPage = () => {
    if (endIndex < dataa?.length) {
      setCurrentPage(currentPage + 1);
      setStartIndex(startIndex + entriesPerPage);
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page when search query changes
  };

  const [dataa, setDataa] = useState();
  useEffect(() => {
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/users`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        // //("yash varshney");
        //(res?.data);
        setDataa(res?.data);
        // //(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);

  const filterIncompleteData = (data) => {
    return data?.filter((item) => !item.j_address || !item.c_phone);
  };

  const filteredData = filterIncompleteData(dataa);
  const displayData = showIncompleteData ? filteredData : dataa;
  const filteredAndSearchedData = displayData?.filter((data) =>
    data?.ac_name.toLowerCase().startsWith(searchQuery.toLowerCase()),
  );

  const currentData = filteredAndSearchedData?.slice(startIndex, endIndex);
  const handleshowIncompleteData = (value) => {
    setShowIncompleteData(value);
    setCurrentPage(1);
    setStartIndex(0);
  };

  const handleDownloadCSV = () => {
    // Find all unique column names present in the data
    const allColumns = new Set();
    filteredAndSearchedData.forEach((row) => {
      Object.keys(row).forEach((column) => {
        if (column !== "ab_password") {
          allColumns.add(column);
        }
      });
    });

    // Convert the set of columns to an alphabetically sorted array
    const sortedColumns = Array.from(allColumns).sort();

    // Filter the data to keep only the columns that have non-null values
    const filteredDataWithColumns = filteredAndSearchedData.map((row) => {
      const newRow = {};
      sortedColumns.forEach((column) => {
        if (
          column !== "ab_password" &&
          row[column] !== null &&
          row[column] !== undefined
        ) {
          newRow[column] = row[column];
        }
      });
      return newRow;
    });

    // Convert the filtered data to CSV format
    const csvData = Papa.unparse(filteredDataWithColumns, {
      header: true, // Include headers in the CSV
      skipEmptyLines: true,
    });

    // Create a downloadable blob and trigger the download
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "member_data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handlePageChange = (newPage) => {
    const totalPages = Math.ceil(
      filteredAndSearchedData?.length / entriesPerPage,
    );
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      setStartIndex((newPage - 1) * entriesPerPage);
    }
  };

  return (
    <div className="team-details">
      <div>
        <h1>Member Details</h1>
      </div>
      <div className="search-bar-container">
        <div className="search-bar" style={{ display: "flex" }}>
          <i className="fa fa-search" aria-hidden="true"></i>
          <input
            placeholder="Search for team name"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>

        <div
          className=""
          style={{
            gap: "2rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            className="toggle-button"
            onClick={() => handleshowIncompleteData(!showIncompleteData)}
          >
            {showIncompleteData ? "Show All Data" : "Show Incomplete Data"}
          </button>
          <button className="toggle-button" onClick={handleDownloadCSV}>
            Download CSV
          </button>
        </div>
      </div>
      <div className="team-table">
        <table className="table-container">
          <thead>
            <tr>
              <th>SNo.</th>
              <th>Member Name</th>
              <th>Email</th>
              <th>Contact No.</th>
              <th>Gender</th>
              <th>Age</th>
              <th>Course & Year</th>
              <th>Organization</th>
              <th>Address</th>
              <th>Country</th>
              <th>State</th>
              <th>Pincode</th>
              <th>Shirt Size</th>
              <th>Bio</th>
              <th>Linkedin</th>
              <th>Twitter</th>
              <th>Github</th>
            </tr>
          </thead>
          <tbody>
            {currentData?.map((data, indexOnPage) => {
              const actualIndex = startIndex + indexOnPage;
              return (
                <tr style={{ border: "1px solid" }}>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {actualIndex + 1}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.ac_name}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.aa_email}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.c_phone}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.e_gender}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.d_age}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.h_course_year}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.i_organization}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.j_address}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.l_country}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.k_res_state}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.m_pincode}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.f_tShirtSize}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    {data.n_bio}
                  </td>
                  <td
                    className=""
                    style={{ border: "1px solid", whiteSpace: "nowrap" }}
                  >
                    <a
                      href={data.p_linkedin}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      Linkedin
                    </a>
                  </td>
                  <td style={{ border: "1px solid" }}>
                    {" "}
                    <a
                      href={data.q_twitter}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      Twitter
                    </a>
                  </td>
                  <td style={{ border: "1px solid" }}>
                    {" "}
                    <a
                      href={data.o_github}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      Github
                    </a>
                  </td>
                </tr>
              );
              // //("done")
            })}
          </tbody>
        </table>
      </div>
      <div className="pagination">
        <button
          className="previous-btn"
          disabled={currentPage === 1}
          onClick={handlePreviousPage}
        >
          Previous
        </button>
        <span
          className="editable-span"
          contentEditable={true}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              const newPage = parseInt(e.currentTarget.textContent);
              handlePageChange(newPage);
              e.currentTarget.blur(); // Remove focus after Enter is pressed
            }
          }}
          onBlur={(e) => {
            const newPage = parseInt(e.currentTarget.textContent);
            handlePageChange(newPage);
          }}
        >
          {currentPage}
        </span>
        <span>
          /{Math.ceil(filteredAndSearchedData?.length / entriesPerPage)}
        </span>

        <button
          className="next-btn"
          disabled={endIndex >= filteredAndSearchedData?.length}
          onClick={handleNextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
}
