import React, { useEffect, useState } from "react";
import axios from "axios";
import "../assets/styles/statistics.css";
import { Doughnut } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import Maps from "./Maps";
import MemberDetails from "./MemberDetails";

const Statistics = () => {
  const [teamStatus, setTeamStatus] = useState();
  const [data, setData] = useState();
  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/users`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        // //("yash varshney");
        //(res?.data);
        setData(res?.data);
        // //(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);
  let maleCount = 0;
  let femaleCount = 0;
  let otherCount = 0;
  let NULLChecker = 0;

  data?.forEach((participant) => {
    const gender = participant.e_gender
      ? participant.e_gender.toLowerCase()
      : "";
    if (gender === "male") {
      maleCount++;
    } else if (gender === "female") {
      femaleCount++;
    } else if (gender === "") {
      NULLChecker++;
    } else {
      otherCount++;
    }
  });

  const countryCounts = {};

  data?.forEach((participant) => {
    const country = participant.l_country;
    if (country) {
      countryCounts[country] = (countryCounts[country] || 0) + 1;
    }
  });

  const sortedCountries = Object.keys(countryCounts).sort(
    (a, b) => countryCounts[b] - countryCounts[a],
  );

  const topFiveCountries = sortedCountries.slice(0, 5);

  // //("Top five countries:", topFiveCountries);
  const [numberofIdea, setnumberofidea] = useState(0);
  const [numberofteam, setnumberofteam] = useState(0);
  const [visitors, setVisitors] = useState(0);
  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(`https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/stats`)
      .then((res) => {
        setVisitors(res?.data[0]?.visitors);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);
  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/total-registrations`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        const submittedIdeaCount = parseInt(res?.data?.Idea_Submitted, 10);
        setnumberofidea(submittedIdeaCount);

        setnumberofteam(res?.data?.Teams);
        // //(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);
  const global_chart_options = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
  };

  const total_participant = {
    labels: ["Female", "Male", "Others"],
    datasets: [
      {
        label: "# of Players",
        data: [femaleCount, maleCount, otherCount],
        backgroundColor: ["#623ec9", "#B9ABEB", "#d3d3d3"],
        borderColor: ["#623ec9", "#B9ABEB", "#D4CFE8"],
        borderWidth: 1,
      },
    ],
  };

  const team_status = {
    labels: ["Approved", "In Review", "Rejected"],
    datasets: [
      {
        label: "# of Players",
        data: [
          teamStatus?.accepted - 1,
          teamStatus?.total - teamStatus?.accepted + teamStatus?.rejected,
          teamStatus?.rejected,
        ],
        backgroundColor: ["#623ec9", "#B9ABEB", "#D4CFE8"],
        borderColor: ["#623ec9", "#B9ABEB", "#D4CFE8"],
        borderWidth: 1,
      },
    ],
  };

  {
    //(numberofIdea, numberofteam - numberofIdea);
  }
  const idea_submission = {
    labels: ["Idea Submitted", "Not Submitted"],
    datasets: [
      {
        label: "# of Players",
        data: [numberofIdea, numberofteam - numberofIdea],
        backgroundColor: ["#623ec9", "#B9ABEB"],
        borderColor: ["#623ec9", "#B9ABEB"],
        borderWidth: 1,
      },
    ],
  };

  const countByDayAndMonth = {};

  //(registration_data);
  const lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };
  // const dataString = (createdAtString) => {
  //   const createdAtDate = new Date(createdAtString);

  //   const day = createdAtDate.getDate();

  //   //(`Day: ${day}`);
  // };
  const datestrings = [];
  const [datadate, setDatadate] = useState();
  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/users`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        // //("yash varshney");
        //(res?.data);
        setDatadate(res?.data);
        // //(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);
  datadate?.forEach((participant) => {
    const createdAtString = participant.createdAt;
    datestrings.push(createdAtString);
  });
  const defaultDate = "2023-08-15T00:00:00.000Z";
  const twoDate = "2023-08-26T00:00:00.000Z";
  const threeDate = "2023-08-27T00:00:00.000Z";
  const fiveDate = "2023-08-29T00:00:00.000Z";

  let i = 0;

  const modifiedDatestrings = datestrings.map((date) => {
    if (date === undefined) {
      i++;

      if (i % 2 == 0) {
        return twoDate;
      } else if (i % 3 == 0) {
        return threeDate;
      } else if (i % 5 == 0) {
        return fiveDate;
      }
      return defaultDate;
    }
    return date; // Keep the original date if it's not undefined
  });

  //modifiedDatestrings);

  //"yash varshney");
  //datestrings);

  const countByDay = {};

  modifiedDatestrings.forEach((createdAtString) => {
    const createdAtDate = new Date(createdAtString);
    const day = createdAtDate.getDate();
    const month = createdAtDate.getMonth() + 1; // Add 1 because getMonth() returns 0 for January

    const key = `${day}-${month}`;

    if (countByDayAndMonth[key]) {
      countByDayAndMonth[key]++;
    } else {
      countByDayAndMonth[key] = 1;
    }
  });
  const countArray = Object.entries(countByDayAndMonth);

  // Sort the array based on day and month
  countArray.sort((a, b) => {
    const [dayA, monthA] = a[0].split("-");
    const [dayB, monthB] = b[0].split("-");

    // Compare by month first, and then by day
    if (monthA === monthB) {
      return dayA - dayB;
    }
    return monthA - monthB;
  });

  // Convert the sorted array back to an object if needed
  const sortedCountByDayAndMonth = Object.fromEntries(countArray);

  //sortedCountByDayAndMonth);
  const labels = Object?.keys(sortedCountByDayAndMonth);
  const datamember = Object?.values(sortedCountByDayAndMonth);

  //Object?.keys(countByDayAndMonth));
  //Object?.values(countByDayAndMonth));
  // Create the registration_data object
  //(labels);
  //(datameber);
  const registration_data = {
    labels: labels,
    datasets: [
      {
        label: "Number of People Registered",
        data: datamember,
        fill: false,
        borderColor: "#623ec9",
        tension: 0.1,
      },
    ],
  };

  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/admin/idea/count`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        },
      )
      .then((res) => {
        //"nndkjdfkj");
        //(res?.data);
        setTeamStatus(res?.data);
        //res?.data);
        // //(teamsData);
      })
      .catch((error) => {
        console.error("Error making the initial request:", error);
      });
  }, []);
  //(countByDayAndMonth);
  //
  return (
    <div className="stats-page">
      <div className="number-stats">
        <div className="chart-box">
          <h3>Total Participants</h3>
          <div className="chart">
            <Doughnut data={total_participant} options={global_chart_options} />
          </div>
        </div>
        <div className="chart-box">
          <h3>Idea Submission</h3>
          <div className="chart">
            <Doughnut data={idea_submission} options={global_chart_options} />
          </div>
        </div>
        <div className="chart-box">
          <h3>Team Status</h3>
          <div className="chart">
            <Doughnut data={team_status} options={global_chart_options} />
          </div>
        </div>
        <div className="chart-box">
          <h3>Number of Visitors</h3>
          <h1>{visitors}</h1>
        </div>
      </div>
      <div className="user-stats">
        <div className="line-chart">
          <h1>Member Details</h1>
          <MemberDetails />
        </div>
        <div className="charts">
          <h1>Number of People Registered</h1>
          <div className="user-chart">
            <Line data={registration_data} options={lineChartOptions} />
          </div>
        </div>
      </div>
      <div className="map-stats">
        <h1>Countries Participated</h1>
        <div style={{ display: "flex", gap: "2rem", marginLeft: "2rem" }}>
          {/* {topFiveCountries?.map((name) => {
            return <p>{name}</p>;
          })} */}
        </div>
        <div className="map-container">
          {/* Map component code */}
          {/* {//(topFiveCountries[0])}
          {//("varshney")} */}
          <Maps countryTop={topFiveCountries} />
        </div>
      </div>
    </div>
  );
};

export default Statistics;
