import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "../assets/styles/user_onboarding_flow.css";
import axios from "axios";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
export default function ForgotComponent() {
  const params = useParams();
  // Forgot password validation.
  const [change, setChange] = useState("");
  const [Error, setError] = useState("");
  const [originalPassword, setoriginalPassword] = useState("");
  const [confirmedPassword, setconfirmedPassword] = useState("");
  const handleChange = (e) => {
    setoriginalPassword(e.target.value);
  };
  const handleChange2 = (e) => {
    setconfirmedPassword(e.target.value);
  };

  function validatePassword(password) {
    // Minimum 8 characters
    if (password.length < 8) {
      return false;
    }

    // Maximum 16 characters
    if (password.length > 16) {
      return false;
    }

    // Contains at least one special character
    if (!/[!@#$%^&*]/.test(password)) {
      return false;
    }

    // Contains at least one capital letter
    if (!/[A-Z]/.test(password)) {
      return false;
    }

    // Password meets all criteria
    return true;
  }
  const match = () => {
    if (originalPassword === "" || confirmedPassword === "") {
      setError("Password can't be empty.");
    } else {
      const isValid = validatePassword(originalPassword);
      if (isValid) {
        if (originalPassword === confirmedPassword) {
          // // // //("It's a match.");
          // https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/reset-password/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhYV9lbWFpbCI6ImFrc2hheXBtaXNocmEwMkBnbWFpbC5jb20iLCJpYXQiOjE2OTA1NTEyMDV9.famXwL-VP1DCdJzFL6_bjqe1zDBoTBbtdT9Nzbug3Ew
          // // // // //(
          //   "https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/reset-password/" +
          //     params?.id
          // );
          const newPassword = originalPassword;
          axios
            .post(
              `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/reset-password/` +
                params?.id,
              {
                newPassword,
              },
            )
            .then((res) => {
              // Handle the message in the React component
              setChange("Password change Successfully.");
              // // // //(res);

              window.location.replace("/login");
            })
            .catch((error) => {
              // // // //(error);
              // Handle error state
            });
        } else {
          setError("Password's don't match.");
        }
      } else {
        setError(
          "Password must contain minimum 8 characters, at least one capital and one special character.",
        );
      }
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  //
  return (
    <>
      <div className="onboard-box">
        <h1
          className="create"
          style={{ whiteSpace: "nowrap", fontSize: "149%" }}
        >
          Create a new password
        </h1>
        <form action="">
          <input
            onChange={handleChange}
            value={originalPassword}
            type="text"
            name="password"
            id="password change-p"
            className="onboard-box-input"
            placeholder="Enter Your Password"
          />
          {/* onChange={handleChange2} */}
          {/* value={confirmedPassword} */}
          <div className="password-input-container">
            <input
              required
              type={showPassword ? "text" : "password"}
              name="password"
              id="password"
              onChange={handleChange2}
              value={confirmedPassword}
              placeholder="Enter Your Password"
              className="onboard-box-input2"
            />
            {confirmedPassword && (
              <span
                className="password-toggle-icon"
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
              </span>
            )}
          </div>
          <p
            id="error"
            style={{
              color: "red",
              marginBottom: "0px",
              paddingLeft: "45px",
              paddingRight: "45px",
            }}
          ></p>
          {change && (
            <h4 style={{ color: "rgb(45, 144, 15)", marginLeft: "1rem" }}>
              {change}
            </h4>
          )}
          {Error && (
            <h4 style={{ color: "red", marginLeft: "1rem" }}>{Error}</h4>
          )}
          <button
            type="button"
            onClick={match}
            id="reset"
            style={{ marginTop: "12.5px", color: "white" }}
          >
            SUBMIT
          </button>
        </form>
      </div>
    </>
  );
}
