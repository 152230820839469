import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import bg from "../assets/images/background_1.png";
import Error from "../components/Error";
import Verified from "../components/Verified";
import { useLocation, useParams } from "react-router-dom";
import axios from "axios";
const ErrorPage = () => {
  const params = useParams();
  // //(params);
  const [card, setCard] = useState();
  const location = useLocation();
  // // //(location.pathname);

  var splitURL = location.pathname.toString().split("/");
  // //(splitURL[2]);
  // //(splitURL[3]);

  //--------------------------------------------------------------------------------------------------------------------------------------------------We have to update the array when we go live

  //

  // male female non-binary others (prefer not to say ) B.Tech CSE 4th  back yearlive-------------------------------------------------------------------------------------"User not found or invalid

  // 'Invalid credentials  login
  // userid"----------------------------------

  const url = `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/verify/${params?.email}/${params?.id}`;
  // //(url);
  // const getFetch = async () => {
  //   await fetch(url)
  //     .then((data) => data.json())
  //     .then((d) => setCard(d));
  //   // //(card);
  //   // //("yash");
  // };
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(url);
        setData(response?.data);
        setCard(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // // //("yash varshney");
  }, []);
  // // //(card?.message);
  return (
    <div
      className="wrapper"
      style={{
        background: "linear-gradient(135deg, #588380, #2f4443)",
        height: "100vh",
        overflow: "auto",
      }}
    >
      {/* <h1>{card?.message}</h1> */}
      <Navbar />
      <Verified data={card?.message} />
    </div>
  );
};

export default ErrorPage;
