import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../assets/styles/sidebar.css";

const Sidebar = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState(false);

  const toggleMenu = () => {
    setActive(!active);
  };
  //
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/admin");
  };

  return (
    <div className={`navigation ${active ? "active" : ""}`}>
      <ul>
        <div className="sidebar-split">
          <li>
            <Link to="/admin/home" className="active-link-sidenav">
              <span className="icon">
                <i className="fa-solid fa-house"></i>
              </span>
              <span className="title">Home</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/member" className="active-link-sidenav">
              <span className="icon">
                <i className="fa-solid fa-people-group"></i>
              </span>
              <span className="title">Idea Submission</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/team" className="active-link-sidenav">
              <span className="icon">
                <i className="fa-sharp fa-solid fa-address-book"></i>
              </span>
              <span className="title">Team List</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/stats" className="active-link-sidenav">
              <span className="icon">
                <i className="fa-solid fa-gear"></i>
              </span>
              <span className="title">Statistics</span>
            </Link>
          </li>
          <li>
            <Link to="/admin/collection" className="active-link-sidenav">
              <span className="icon">
                <i className="fa-solid fa-people-group"></i>
              </span>
              <span className="title">Members Details</span>
            </Link>
          </li>
        </div>
        <li>
          <Link
            to="/admin"
            className="active-link-sidenav"
            onClick={handleLogout}
          >
            <span className="icon">
              {" "}
              <i className="fa-solid fa-right-from-bracket"></i>
            </span>
            <span className="title">Logout</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
