import React from "react";
import "../assets/styles/VerfiedCss.css";
import { useNavigate } from "react-router-dom";
const Start = () => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/login");
    // // //("Redirecting user to login page...");
  };
  const handleRegister = () => {
    navigate("/Register");
    // // //("Redirecting user to Register page...");
  };
  //
  return (
    <div className="email-verified-page">
      {/* <h1>{// // //(data)}</h1> */}
      <div className="content">
        <h1 className="Email-text">Welcome to HTM PORTAL</h1>

        <button className="email_button" onClick={() => handleRegister()}>
          Register
        </button>
        <button
          className="email_button"
          id="login-button"
          onClick={() => handleLogin()}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default Start;
