import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { HiOutlineEye, HiOutlineEyeOff } from "react-icons/hi";
import Modal from "react-modal";
import { ImCross } from "react-icons/im";

const RegisterComponent = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorTimer, setErrorTimer] = useState(null);
  const [successTimer, setSuccessTimer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (successMessage) {
      const successTimer = setTimeout(() => {
        setSuccessMessage("");
        // Redirect to the login page after 3 seconds
        navigate("/login");
      }, 3000);

      return () => {
        clearTimeout(successTimer);
      };
    }
  }, [successMessage, navigate]);
  const handleChangeName = (e) => {
    setName(e.target.value);
  };
  useEffect(() => {
    if (successMessage) {
      const timeoutId = setTimeout(() => {
        setSuccessMessage("");
      }, 10000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [successMessage]);
  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };
  useEffect(() => {
    document.title = "Register HTM";
    return () => {
      document.title = "Portal | HTM";
    };
  }, []);
  const [rt, setRt] = useState("");
  const validatePassword = () => {
    const passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()]).{8,16}$/;
    if (!passwordRegex.test(password)) {
      setPasswordError(
        "Password must have 8 to 16 characters, at least one uppercase letter, one lowercase letter, one special character, and one number."
      );
      setErrorTimer(setTimeout(() => setPasswordError(""), 3000));
      // setRt(
      //   // "Password must have 8 to 16 characters, at least one uppercase letter, one lowercase letter, one special character, and one number."
      // );
      return false;
      // // //("jj");
      // // //("yash");
    } else {
      setPasswordError("");
      return true;
    }
  };
  const isValidEmail = (email) => {
    const emailRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validatePassword();
    // // //(isValid);
    // // // //(validatePassword());
    clearTimeout(errorTimer); // Clear existing error timer
    clearTimeout(successTimer);
    if (!isValidEmail(email)) {
      setPasswordError("Please enter a valid email address");
      setTimeout(() => {
        setPasswordError(null);
      }, 3000);
      setIsLoading(false);
      return;
    }
    const data = {
      ac_name: name,
      aa_email: email,
      ab_password: password,
    };

    // // //(password.length);
    // // //("done");
    // // //(passwordError);
    if (password.length >= 8 && password.length <= 16 && isValid) {
      setIsLoading(true); // Enable loading state

      // // //(passwordError);
      // // //("uuuuuu");

      axios
        .post(
          `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/register-user`,
          data
        )
        .then((response) => {
          if (
            response.data.message ===
            "Email already exists, please use a different username"
          ) {
            setPasswordError("Email already exists.");
            setErrorTimer(setTimeout(() => setPasswordError(""), 3000));
          } else {
            setSuccessMessage(
              "A verification mail has been sent to the registered email."
            );
            setName("");
            setEmail("");
            setPassword("");
            setSuccessTimer(setTimeout(() => setSuccessMessage(""), 3000));
            setIsLoading(false);
          }
          // Handle the message in the React component
        })
        .catch((error) => {
          // // //(error?.response?.data?.error);
          let errorMessage = "An error occurred";
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
          }

          setPasswordError(error?.response?.data?.error);
          setErrorTimer(setTimeout(() => setPasswordError(""), 3000));
          // Handle error state
        })
        .finally(() => {
          setIsLoading(false); // Disable loading state
        });
    }
  };
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };
  const [register, setRegister] = useState();
  const [idea, setIdea] = useState();
  useEffect(() => {
    // Replace with the actual ID
    const jwtToken = localStorage.getItem("token");

    axios
      .get(
        `https://r8t24urva1.execute-api.eu-north-1.amazonaws.com/prod/stats`,
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // //("yash varshney");
        const tracksString = res?.data[0]?.registration;
        console.log({ tracksString });
        setRegister(tracksString);
        setIdea(res?.data[0]?.ideaSubmission);
      })
      .catch((error) => {
        // console.error("Error making the initial request:", error);
      });
  }, []);

  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [aa_email, setaa_email] = useState("");
  const [resetTitle, setResetTitle] = useState("Registration Closed");
  const [modalHeight, setModalHeight] = useState("310px");
  const [timerEnd, setTimerEnd] = useState("none");
  const [resetMessage, setResetMessage] = useState(
    "Registration closed. Already registered? Proceed to login."
  );
  const [resetFormVisible, setResetFormVisible] = useState(true);
  const [timer, setTimer] = useState(0);
  const openModal = () => {
    setModalIsOpen(true);
    setaa_email("");
    setResetTitle("Registration Closed");
    setResetMessage(
      "Registration closed. Already registered? Proceed to login."
    );
    setTimerEnd("none");

    setResetFormVisible(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setaa_email("");
    setModalHeight("310px");
    setTimer(0); // Reset timer when closing the modal
  };
  const handleLogin = () => {
    navigate("/login");
  };
  return (
    <>
      {register === false ? (
        <Modal
          isOpen={true}
          onRequestClose={closeModal}
          contentLabel="Reset Password Modal"
          style={{ content: { borderRadius: "20px", height: modalHeight } }}
        >
          <div className="modal-top">
            <h1 id="reset-title">{resetTitle}</h1>
            {/* <button id="reset-button" onClick={closeModal}>
              <ImCross />
            </button> */}
          </div>
          <p id="reset-p" dangerouslySetInnerHTML={{ __html: resetMessage }} />
          {resetFormVisible && (
            <form id="reset-form">
              <br />
              <button
                className="submit-button"
                type="button"
                onClick={handleLogin}
              >
                Login
              </button>
            </form>
          )}
        </Modal>
      ) : (
        <div className="onboard-box">
          <h1>REGISTER</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              id="name"
              value={name}
              onChange={handleChangeName}
              required
              placeholder="Enter Your Full Name"
              className="onboard-box-input"
            />
            <input
              type="email"
              name="email"
              id="email-log"
              value={email}
              onChange={handleChangeEmail}
              required
              placeholder="Enter Your Email"
              className="onboard-box-input"
            />
            <div className="password-input-container">
              <input
                required
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                value={password}
                onChange={handleChangePassword}
                placeholder="Enter Your Password"
                className="onboard-box-input2"
              />
              {password && (
                <span
                  className="password-toggle-icon"
                  onClick={togglePasswordVisibility}
                >
                  {showPassword ? <HiOutlineEyeOff /> : <HiOutlineEye />}
                </span>
              )}
            </div>
            {passwordError && (
              <p className="password-error-message error-in">{passwordError}</p>
            )}
            {successMessage && (
              <p className="success-message">{successMessage}</p>
            )}
            <button
              type="submit"
              id="log"
              style={{ marginTop: "12.5px" }}
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="loading-circle" /> // Add a rotating circle (CSS animation)
              ) : (
                "REGISTER"
              )}
            </button>
          </form>
          <p>
            Already have an account? <Link to="/login">Login</Link>
          </p>
        </div>
      )}
    </>
  );
};

export default RegisterComponent;
