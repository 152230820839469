import React from "react";
import "../assets/styles/VerfiedCss.css";
import { useNavigate } from "react-router-dom";
// Link, Routes, Route, removed from the line above

const EmailVerifiedPage = (data) => {
  const navigate = useNavigate();
  const handleLogin = () => {
    navigate("/login");
    // // //("Redirecting user to login page...");
  };
  //
  return (
    <div className="email-verified-page">
      {/* <h1>{// // //(data)}</h1> */}
      <div className="content">
        <h1 className="Email">Email Verified!</h1>
        <p className="Email-content">
          Your email has been successfully verified.
        </p>
        <p>You can now log in to your account.</p>
        <button onClick={() => handleLogin()} className="email_button">
          Log In
        </button>
      </div>
    </div>
  );
};

export default EmailVerifiedPage;
